import {
  Button,
  Checkbox,
  Panel,
  SelectField,
  useTranslation,
} from "h11-client-component-lib";
import { useState } from "react";
import { DoorClosed } from "react-bootstrap-icons";

export const AssignRoomsPanel = ({ groupIndex }: { groupIndex: number }) => {
  const { t } = useTranslation();
  const [floor, setFloor] = useState<number>();
  const [cleanOnly, setCleanOnly] = useState(false);
  return (
    <Panel variant="compact" className="AssignRoomsPanel">
      <SelectField<number>
        fieldWidth={60}
        labelPosition="left"
        label={t("floor")}
        items={[0, 1, 2, 3]}
        itemIdExtractor={i => i}
        valueToString={i => i.toString()}
        multiSelect={false}
        value={floor}
        onChange={setFloor}
      />
      <Checkbox
        id={`clean-only-${groupIndex}`}
        checked={cleanOnly}
        onChange={setCleanOnly}
        label={t("clean_only")}
      />
      <Button variant="secondary" icon={<DoorClosed />}>
        {t("assign_rooms")}
      </Button>
    </Panel>
  );
};
