import { Panel, useTranslation } from "h11-client-component-lib";
import { RoomsTable } from "./RoomsTable";

// Fixed widths for use in header and child group components
export const termsAndRoomTypesSectionHeadersWidths = [
  300, 80, 48, 48, 48, 80, 48,
] as const;

export const CurrentGuestsSection = () => {
  const { t } = useTranslation();
  return (
    <Panel
      className="CurrentGuestsSection"
      label={t("current_guests")}
      variant="naked">
      <RoomsTable />
    </Panel>
  );
};
