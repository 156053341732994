import { useEffect } from "react";
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import "./index.scss";
import "./to_lib.scss";
import { ReservationDetailEnumsFragment$key } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { reservationDetailEnumsFragment } from "./graphql/ReservationDetailEnumsFragment";
import { ReservationCreateQuery } from "@relay-generated/ReservationCreateQuery.graphql";
import { reservationCreateQuery } from "./graphql/ReservationCreateQuery";
import { ReservationForm } from "./ReservationForm";

export function ReservationCreatePage() {
  const [queryRef, loadQuery] = useQueryLoader<ReservationCreateQuery>(
    reservationCreateQuery,
  );

  useEffect(() => {
    loadQuery({});
  }, []);

  return queryRef && <LoadedReservationCreatePage queryRef={queryRef} />;
}

function LoadedReservationCreatePage({
  queryRef,
}: {
  queryRef: PreloadedQuery<ReservationCreateQuery>;
}) {
  const reservationData = usePreloadedQuery(reservationCreateQuery, queryRef);

  const enums = useFragment<ReservationDetailEnumsFragment$key>(
    reservationDetailEnumsFragment,
    reservationData,
  );

  return <ReservationForm data={{}} enums={enums} autoAddInitialData />;
}
