import { SelectField, SelectFieldProps } from "h11-client-component-lib";
import { Controller, Path, useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath, FieldPathValue } from "react-hook-form/dist/types/path";

export type FormSelectFieldProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T,
  TFieldValue,
> = Omit<SelectFieldProps<TFieldValue>, "value" | "name" | "onChange"> & {
  field: Path<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FormSelectField<
  T extends FieldValues,
  TFieldValue extends FieldPathValue<T, FieldPath<T>>,
>({ field, ...props }: FormSelectFieldProps<T, TFieldValue>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => (
        // FIXME multiselecgt
        <SelectField
          {...props}
          value={
            props.items.find(
              i => i && props.itemIdExtractor(i) === fieldObj.value,
            ) ?? undefined
          }
          onChange={(value, event) =>
            fieldObj.onChange(value ? props.itemIdExtractor(value) : undefined)
          }
          multiSelect={false}
          selectAllLabel={undefined}
          // FIXME errors and mandatory
        />
      )}
    />
  );
}
