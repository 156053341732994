import {
  FlowLayout,
  FormFieldAligner,
  NumberField,
  SelectField,
  Switch,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { useFormContext } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";
import { PricesTable } from "./PricesTable";

export const AccommodationPanel = ({ groupIndex }: { groupIndex: number }) => {
  const { t } = useTranslation();

  return (
    <VerticalStack style={{ flex: "1", minWidth: 300 }}>
      <FlowLayout variant="compact">
        {/*TODO Předělat na FormSelectField až bude pricelist v API*/}
        <SelectField
          label={t("price_list")}
          value={undefined}
          onChange={() => {}}
          items={[]}
          valueToString={value => value}
          itemIdExtractor={item => item}
        />
        {/*TODO */}
        <SelectField
          label={t("discount")}
          value={undefined}
          onChange={() => {}}
          items={[]}
          valueToString={value => value}
          itemIdExtractor={item => item}
        />
        {/*TODO */}
        <FormFieldAligner>
          <NumberField value={undefined} onChange={() => {}} fieldWidth={60} />
        </FormFieldAligner>
        <FormFieldAligner>
          <SelectField
            fieldWidth={60}
            value={"%"}
            onChange={() => {}}
            items={["%"]}
            valueToString={value => value}
            itemIdExtractor={item => item}
          />
        </FormFieldAligner>
        <div style={{ flex: "1" }} />
        <FormFieldAligner>
          <Switch
            label={t("shared_accommodation")}
            id={`group-${groupIndex}-shared-accommodation-switch`}
            onChange={() => {}}
          />
        </FormFieldAligner>
      </FlowLayout>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Switch
          label={t("edit_price_mask")}
          id={`group-${groupIndex}-price-mask-switch`}
          onChange={() => {}}
        />
        <PricesTable groupIndex={groupIndex} />
      </div>
    </VerticalStack>
  );
};
