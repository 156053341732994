import * as React from "react";
import { useMemo } from "react";
import {
  ExtraBed,
  FlowLayout,
  Icon,
  RoomNumber,
  useTranslation,
} from "h11-client-component-lib";
import { ChatSquareTextFill, ClipboardCheckFill } from "react-bootstrap-icons";
import { useFormContext, useWatch } from "react-hook-form";
import "./RoomHeaderRow.scss";
import { ReservationFormData } from "../reservationData";
import { useReservationContext } from "../ReservationFormContext";

interface RoomHeaderRowProps {
  roomIndex: number;
}

export const RoomHeaderRow = ({ roomIndex }: RoomHeaderRowProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  const room = useWatch({ control, name: `checkedInRooms.${roomIndex}` });
  const guests = useWatch({
    control,
    name: `checkedInRooms.${roomIndex}.guests`,
  });

  const {
    enums: { rooms },
  } = useReservationContext();

  const assignedRoom = useMemo(() => {
    return rooms.find(rt => rt._id === room.roomId);
  }, [room.roomId]);

  return (
    <tr className="RoomHeaderRow">
      <td
        rowSpan={
          guests.length + 1 /* dummy hidden cells */ + 1 /*add guest button*/
        }>
        <div className="content">
          <FlowLayout variant="compact2" style={{ alignItems: "center" }}>
            <div className="item">
              {assignedRoom && <RoomNumber>{assignedRoom.code}</RoomNumber>}
            </div>
          </FlowLayout>
          <FlowLayout variant="compact2" style={{ alignItems: "center" }}>
            <div className="item">
              <Icon
                icon={ChatSquareTextFill}
                tooltip={t("room_note_tooltip")}
              />
            </div>
            <div className="item">
              <Icon
                icon={ClipboardCheckFill}
                tooltip={t("room_trace_tooltip")}
              />
            </div>
            <div className="item">
              <Icon icon={ExtraBed} tooltip={t("room_extra_bed_tooltip")} />
            </div>
          </FlowLayout>
        </div>
      </td>
      <td colSpan={7} />
    </tr>
  );
};
