import {
  Button,
  Panel,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { PlusCircle } from "react-bootstrap-icons";
import { RoomGroupPanel } from "./room_group/RoomGroupPanel";
import { UseFieldArrayReturn } from "react-hook-form";
import { ReservationFormData } from "../reservationData";

// Fixed widths for use in header and child group components
export const termsAndRoomTypesSectionHeadersWidths = [
  200, 100, 64, 64, 64, 80, 48,
] as const;

export const TermsAndRoomTypesSection = ({
  groups,
  onAddRoomGroup,
}: {
  groups: UseFieldArrayReturn<ReservationFormData, "groups">;
  onAddRoomGroup: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Panel
      className="TermsAndRoomTypesSection"
      label={t("terms_and_room_types")}
      variant="naked"
      controls={
        <Button
          variant="secondary"
          onClick={onAddRoomGroup}
          icon={<PlusCircle />}>
          {t("add_reservation_row")}
        </Button>
      }>
      <VerticalStack fullWidth variant="compact">
        <TermsAndRoomTypesHeader />
        <VerticalStack fullWidth>
          {groups.fields.map((g, index) => (
            <RoomGroupPanel
              key={g.id}
              groupIndex={index}
              onDelete={() => groups.remove(index)}
            />
          ))}
        </VerticalStack>
      </VerticalStack>
    </Panel>
  );
};

const TermsAndRoomTypesHeader = () => {
  const { t } = useTranslation();
  return (
    //Right padding should have same width as RoomGroupHeader top right buttons including expand/collapse button
    <div className="header" style={{ paddingRight: 130 }}>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[0],
          minWidth: termsAndRoomTypesSectionHeadersWidths[0],
        }}>
        {t("term")}
      </div>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[1],
          minWidth: termsAndRoomTypesSectionHeadersWidths[1],
        }}>
        {t("type")}
      </div>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[2],
          minWidth: termsAndRoomTypesSectionHeadersWidths[2],
        }}>
        {t("rooms_count")}
      </div>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[3],
          minWidth: termsAndRoomTypesSectionHeadersWidths[3],
        }}>
        {t("adults_count")}
      </div>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[4],
          minWidth: termsAndRoomTypesSectionHeadersWidths[4],
        }}>
        {t("children_count")}
      </div>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[5],
          minWidth: termsAndRoomTypesSectionHeadersWidths[5],
        }}>
        {t("price_list")}
      </div>
      <div
        style={{
          width: termsAndRoomTypesSectionHeadersWidths[6],
          minWidth: termsAndRoomTypesSectionHeadersWidths[6],
        }}>
        {t("mask")}
      </div>
    </div>
  );
};
