import { useFormContext, useWatch } from "react-hook-form";
import * as React from "react";
import { ChatSquareText, Person } from "react-bootstrap-icons";
import {
  Button,
  IconButton,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import { clsx } from "clsx";
import { ReservationFormData } from "../reservationData";

export function GuestRow({
  roomIndex,
  guestIndex,
}: {
  roomIndex: number;
  guestIndex: number;
}) {
  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  const guest = useWatch({
    control,
    name: `checkedInRooms.${roomIndex}.guests.${guestIndex}`,
  });

  return (
    <tr className={clsx("GuestRow")}>
      <td>
        <IconButton icon={Person} tooltip={t("guest_profile_detail_tooltip")} />
      </td>
      <td>{guest.lastName}</td>
      <td>{guest.firstName}</td>
      <td>{guest.birthDate}</td>
      <td>{guest.citizenshipCountry}</td>
      <td>{guest.checkinDate}</td>
      <td>
        {guest.note && (
          <Tooltipped tooltip={guest.note}>
            <ChatSquareText />
          </Tooltipped>
        )}
      </td>
      <td>{/*mistni poplatek*/}</td>
      <td>
        <Button tabIndex={-1} onClick={() => alert("TODO")}>
          {t("room_detail")}
        </Button>
      </td>
    </tr>
  );
}

// FIXME přejmenovat adresář dates_and_room_types na terms_and_room_types
