import {
  Button,
  FlowLayout,
  Heading,
  TextField,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { ClipboardPlus, Eye } from "react-bootstrap-icons";
import { FormTextField } from "../../../to_lib/FormTextField";
import { ReservationFormData } from "../../reservationData";

export function NotePanel() {
  const { t } = useTranslation();
  return (
    <FlowLayout variant="compact" style={{ flex: "1", alignItems: "stretch" }}>
      <VerticalStack
        style={{ flex: "1 1 200px", minWidth: 200 }}
        variant="compact">
        <Heading icon={<ClipboardPlus />}>{t("reservation_note")}</Heading>
        <FormTextField<ReservationFormData>
          field="header.note"
          multiline
          style={{ flex: "1" }}
        />
        <FlowLayout variant="compact">
          <Button variant="secondary">WYSIWYG</Button>
          <Button variant="secondary">
            {t("note_from_selection_abbreviation")}
          </Button>
          <Button variant="secondary">
            {t("trace_from_selection_abbreviation")}
          </Button>
        </FlowLayout>
      </VerticalStack>
      <VerticalStack
        style={{ flex: "1 1 200px", minWidth: 200 }}
        variant="compact">
        <Heading>{t("external_information")}</Heading>
        {/*FIXME až bude external note změnit na formtextfield*/}
        <TextField value="" multiline style={{ flex: "1" }} />
        <FlowLayout variant="compact" style={{ justifyContent: "flex-end" }}>
          <Button
            variant="secondary"
            icon={<Eye />}
            style={{ justifySelf: "flex-end" }}>
            {t("preview")}
          </Button>
        </FlowLayout>
      </VerticalStack>
    </FlowLayout>
  );
}
