import { CollapsiblePanel } from "../../../to_lib/CollapsiblePanel";
import { ReservationFormData } from "../../reservationData";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  SimpleTab,
  SimpleTabs,
  useTranslation,
} from "h11-client-component-lib";
import { RoomsAndGuestsPanel } from "../rooms_and_guests/RoomsAndGuestsPanel";
import { RoomGroupHeader } from "./RoomGroupHeader";
import { AccommodationPanel } from "../accommodation/AccommodationPanel";
import { AdditionalServicesPanel } from "../additional_services/AdditionalServicesPanel";
import { TracesPanel } from "../../main/traces/TracesPanel";
import { InventoryPanel } from "../inventory/InventoryPanel";
import { FbPanel } from "../f_and_b/FbPanel";

export const RoomGroupPanel = ({
  groupIndex,
  onDelete,
}: {
  groupIndex: number;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext<ReservationFormData>();

  const roomsFieldArray = useFieldArray({
    control,
    name: `groups.${groupIndex}.rooms`,
  });

  console.debug("RoomGroupPanel render");

  return (
    <CollapsiblePanel variant="compact" className="RoomGroupPanel">
      <CollapsiblePanel.Header>
        <RoomGroupHeader
          groupIndex={groupIndex}
          onDelete={onDelete}
          roomsFieldArray={roomsFieldArray}
        />
      </CollapsiblePanel.Header>
      <CollapsiblePanel.Content>
        <SimpleTabs>
          <SimpleTab title={t("rooms_and_guests")}>
            <RoomsAndGuestsPanel
              groupIndex={groupIndex}
              roomsFieldArray={roomsFieldArray}
            />
          </SimpleTab>
          <SimpleTab title={t("accommodation")}>
            <AccommodationPanel groupIndex={groupIndex} />
          </SimpleTab>
          <SimpleTab title={t("additional_services")}>
            <AdditionalServicesPanel groupIndex={groupIndex} />
          </SimpleTab>
          <SimpleTab title={t("f_and_b")}>
            <FbPanel groupIndex={groupIndex} />
          </SimpleTab>
          <SimpleTab title={t("traces")}>
            <TracesPanel />
          </SimpleTab>
          <SimpleTab title={t("inventory")}>
            <InventoryPanel groupIndex={groupIndex} />
          </SimpleTab>
        </SimpleTabs>
      </CollapsiblePanel.Content>
    </CollapsiblePanel>
  );
};
