import * as React from "react";
import {
  DateRangeField,
  DateRangeFieldProps,
  FullDateRange,
} from "h11-client-component-lib";
import { Path, PathValue, useFormContext, useWatch } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import dayjs from "dayjs";

export type FormDateRangeFieldProps<T> = Omit<
  DateRangeFieldProps,
  "value" | "name" | "onChange"
> & {
  startField: Path<T>;
  endField: Path<T>;
};

export function FormDateRangeField<T extends FieldValues>({
  startField,
  endField,
  ...props
}: FormDateRangeFieldProps<T>) {
  const { control, setValue } = useFormContext<T>();

  // FIXME
  //const errors = fieldError(formik, field);
  //const mandatory = fieldMandatory(formik, field);

  const startDate = useWatch({ control, name: startField });
  const endDate = useWatch({ control, name: endField });
  const dateRange: FullDateRange | undefined =
    startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : undefined;

  return (
    <DateRangeField
      value={dateRange}
      onChange={v => {
        setValue(startField, v?.[0] as PathValue<T, Path<T>>);
        setValue(endField, v?.[1] as PathValue<T, Path<T>>);
      }}
      {...props}
      // FIXME
      //error={!!errors}
      //mandatory={mandatory}
      //helperText={errors?.toString() ?? props.helperText}
    />
  );
}
