import { Button, useTranslation } from "h11-client-component-lib";
import "./ServicesTable.scss";
import { ServiceRow } from "./ServiceRow";
import { PlusCircle } from "react-bootstrap-icons";

export const DUMMY_SERVICES = [
  {
    id: 1,
    service: "parking",
    // TODO zrevidovat výraz burden
    burdeningFrequency: "selected_days",
    burden: "room",
    unitPrice: 100,
    currency: "CZK",
    totalPrice: 200,
    groupBurdening: true,
  },
  {
    id: 2,
    service: "parking",
    // TODO zrevidovat výraz burden
    burdeningFrequency: "selected_days",
    burden: "room",
    unitPrice: 100,
    currency: "CZK",
    totalPrice: 200,
    groupBurdening: true,
  },
  {
    id: 3,
    service: "parking",
    // TODO zrevidovat výraz burden
    burdeningFrequency: "selected_days",
    burden: "room",
    unitPrice: 100,
    currency: "CZK",
    totalPrice: 200,
    groupBurdening: true,
  },
];

export const ServicesTable = ({ groupIndex }: { groupIndex: number }) => {
  // Ordinary table is used, because Table component would be a overhead
  // TODO implement ordinary table also as a component? i. e. Table and rename the current Table to DataTable?

  const { t } = useTranslation();

  return (
    <div className="Table ServicesTable">
      <table>
        <thead>
          <tr>
            <th>{t("service_or_package")}</th>
            <th>{t("burdening_frequency")}</th>
            <th>{t("burden")}</th>
            <th className="right">{t("unit_price_abbreviation")}</th>
            <th>{t("currency")}</th>
            <th className="right">{t("total_price")}</th>
            <th className="center">{t("group_burdening")}</th>
          </tr>
        </thead>
        <tbody>
          {DUMMY_SERVICES.map(s => (
            <ServiceRow key={s.id} service={s} />
          ))}
        </tbody>
      </table>
      <Button
        variant="textual"
        icon={<PlusCircle />}
        style={{ margin: "8px 0" }}>
        {t("add_item")}
      </Button>
    </div>
  );
};
