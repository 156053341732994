import {
  Checkbox,
  SelectField,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";

export function ConditionsPanel() {
  const { t } = useTranslation();
  return (
    <VerticalStack variant="spacious">
      <SelectField
        // TODO
        label={t("cancellation_conditions")}
        items={["< 30 dnů = 100 %"]}
        valueToString={i => i}
        itemIdExtractor={i => i}
        value={undefined}
        onChange={() => {}}
      />
      <Checkbox label={t("beyond_max_allocations")} />
    </VerticalStack>
  );
}
