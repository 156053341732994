import {
  FlowLayout,
  SelectField,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { FbTable } from "./FbTable";

export const FbPanel = ({ groupIndex }: { groupIndex: number }) => {
  const { t } = useTranslation();

  return (
    <VerticalStack style={{ minWidth: 300 }}>
      <FlowLayout>
        <SelectField
          label={t("f_and_b_plan")}
          value={undefined}
          onChange={() => {}}
          items={[]}
          valueToString={value => value}
          itemIdExtractor={item => item}
        />
        <SelectField
          label={t("burden_variant")}
          value={undefined}
          onChange={() => {}}
          items={[]}
          valueToString={value => value}
          itemIdExtractor={item => item}
        />
        <SelectField
          label={t("facility")}
          value={undefined}
          onChange={() => {}}
          items={[]}
          valueToString={value => value}
          itemIdExtractor={item => item}
        />
      </FlowLayout>
      <FbTable groupIndex={groupIndex} />
    </VerticalStack>
  );
};
