import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import { Stickies, Trash } from "react-bootstrap-icons";
import { FormSelectField } from "../../../to_lib/FormSelectField";
import { useReservationContext } from "../../ReservationFormContext";
import { UseFieldArrayReturn, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { ReservationFormData } from "../../reservationData";
import {
  FlowLayout,
  IconButton,
  SelectField,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import { FormDateRangeField } from "../../../to_lib/FormDateRangeField";
import { GuestsCountSelectField } from "./GuestsCountSelectField";
import { RoomsCountSelectField } from "./RoomCountSelectField";

export const RoomGroupHeader = ({
  groupIndex,
  onDelete,
  roomsFieldArray,
}: {
  groupIndex: number;
  onDelete: () => void;
  roomsFieldArray: UseFieldArrayReturn<ReservationFormData, "groups.0.rooms">;
}) => {
  const { t, tp } = useTranslation();

  const {
    enums: { roomTypes },
  } = useReservationContext();

  /*const checkinDateWatch = useWatch({
    name: `groups.${groupIndex}.checkinDate`,
  }) as dayjs.Dayjs | string;
  const checkoutDateWatch = useWatch({
    name: `groups.${groupIndex}.checkoutDate`,
  }) as dayjs.Dayjs | string;

  const nights = useMemo(
    () =>
      checkinDateWatch &&
      checkoutDateWatch &&
      dayjs(checkoutDateWatch)?.diff(checkinDateWatch, "day"),
    [checkinDateWatch, checkoutDateWatch],
  );*/

  const rooms = useWatch({
    name: `groups.${groupIndex}.rooms`,
  });

  console.debug("RoomGroupHeader render");

  const reservationContext = useReservationContext();

  return (
    <div className="RoomGroupHeader">
      <FlowLayout variant="compact" style={{ flex: "1", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
            width: termsAndRoomTypesSectionHeadersWidths[0],
          }}>
          <FormDateRangeField
            placeholder={"???"}
            variant="naked-big"
            min={dayjs()}
            multiMonth
            style={{ width: "100%" }}
            startField={`groups.${groupIndex}.checkinDate`}
            endField={`groups.${groupIndex}.checkoutDate`}
          />
        </div>
        <div style={{ width: termsAndRoomTypesSectionHeadersWidths[1] }}>
          <FormSelectField
            fieldWidth="autosize"
            field={`groups.${groupIndex}.roomType`}
            items={roomTypes}
            placeholder="???"
            variant="naked-big"
            // FIXME proč může být id a name null? Asi to je kvůli unionu?
            valueToString={value => value.code ?? "N/A TODO"}
            itemIdExtractor={item => item._id ?? "N/A TODO"}
          />
        </div>
        <RoomsCountSelectField roomsFieldArray={roomsFieldArray} />
        <GuestsCountSelectField
          rooms={rooms}
          roomsFieldArray={roomsFieldArray}
          childCategories={[
            {
              id: null,
              name: tp("adults", 2),
            },
          ]}
        />
        <GuestsCountSelectField
          rooms={rooms}
          roomsFieldArray={roomsFieldArray}
          childCategories={[
            ...reservationContext.enums.childCategories.map(c => ({
              id: c._id,
              name: c.name,
            })),
            // TODO dummy
            {
              id: 1,
              name: "0 - 3",
            },
            {
              id: 2,
              name: "4 - 11",
            },
            {
              id: 3,
              name: "12 - 17",
            },
          ]}
        />
        {/*TODO Předělat na FormSelectField až bude pricelist v API*/}
        <SelectField
          fieldWidth={termsAndRoomTypesSectionHeadersWidths[5]}
          value={undefined}
          onChange={() => {}}
          items={reservationContext.enums.priceLists}
          // FIXME proč může být id a name null? Asi to je kvůli unionu?
          valueToString={value => value.name}
          itemIdExtractor={item => item._id}
        />
        <Tooltipped tooltip={t("price_mask_tooltip")}>
          <div
            style={{
              display: "flex",
              width: termsAndRoomTypesSectionHeadersWidths[6],
            }}>
            <div className="price-mask-badge">M</div>
          </div>
        </Tooltipped>
      </FlowLayout>
      <div
        style={{
          display: "flex",
          margin: "0 8px",
        }}>
        <IconButton
          onClick={() => alert("TODO")}
          style={{ margin: 8 }}
          tooltip={t("clone_group_tooltip")}
          icon={Stickies}
        />
        <IconButton
          onClick={onDelete}
          style={{ margin: 8 }}
          tooltip={t("delete_group_tooltip")}
          icon={Trash}
        />
      </div>
    </div>
  );
};
