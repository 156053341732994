/**
 * @generated SignedSource<<1b1943b65886278135e7cd82e7c5ca4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReservationCreateQuery$variables = Record<PropertyKey, never>;
export type ReservationCreateQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReservationDetailEnumsFragment">;
};
export type ReservationCreateQuery = {
  response: ReservationCreateQuery$data;
  variables: ReservationCreateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationCreateQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ReservationDetailEnumsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReservationCreateQuery",
    "selections": [
      {
        "alias": "roomTypes",
        "args": [
          {
            "kind": "Literal",
            "name": "spaceCategory",
            "value": "ROOM"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "spaceTypeList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TString100",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "lang",
                        "value": "cs"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "translation",
                    "storageKey": "translation(lang:\"cs\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RoomType",
            "abstractKey": null
          }
        ],
        "storageKey": "spaceTypeList(spaceCategory:\"ROOM\")"
      },
      {
        "alias": "rooms",
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "spaceList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v4/*: any*/),
            "type": "Room",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "sources",
        "args": null,
        "concreteType": "ReservationSource",
        "kind": "LinkedField",
        "name": "reservationSourceList",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": "origins",
        "args": null,
        "concreteType": "ReservationOrigin",
        "kind": "LinkedField",
        "name": "reservationOriginList",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": "childCategories",
        "args": null,
        "concreteType": "ChildCategory",
        "kind": "LinkedField",
        "name": "childCategoryList",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageFrom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageTo",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "priceLists",
        "args": null,
        "concreteType": "PriceList",
        "kind": "LinkedField",
        "name": "pricelistList",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a319b206de6c27bf8a6e23eeef2274f4",
    "id": null,
    "metadata": {},
    "name": "ReservationCreateQuery",
    "operationKind": "query",
    "text": "query ReservationCreateQuery {\n  ...ReservationDetailEnumsFragment\n}\n\nfragment ReservationDetailEnumsFragment on Query {\n  roomTypes: spaceTypeList(spaceCategory: ROOM) {\n    __typename\n    ... on RoomType {\n      _id: id\n      code\n      beds\n      name {\n        translation(lang: \"cs\")\n      }\n    }\n  }\n  rooms: spaceList {\n    __typename\n    ... on Room {\n      _id: id\n      code\n      name\n    }\n  }\n  sources: reservationSourceList {\n    _id: id\n    code\n    name\n  }\n  origins: reservationOriginList {\n    _id: id\n    code\n    name\n  }\n  childCategories: childCategoryList {\n    _id: id\n    name\n    ageFrom\n    ageTo\n  }\n  priceLists: pricelistList {\n    _id: id\n    code\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ce727697f4e782691b75c4f3d740305";

export default node;
