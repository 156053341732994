import {
  ActiveColumnFilter,
  Button,
  DateField,
  DateRangeField,
  FlowGroup,
  FlowLayout,
  FormItem,
  FullDateRange,
  IconButton,
  Page,
  PartialDateRange,
  RowSelection,
  SearchField,
  SelectFilterDef,
  Suspense,
  Tag,
  Tags,
  ToggleButtonGroup,
  ToggleItem,
  useExtendedFilters,
  useTranslation,
} from "h11-client-component-lib";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ArrowCounterclockwise, PlusCircle } from "react-bootstrap-icons";
import {
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import dayjs from "dayjs";
import {
  groupedStateFilters,
  HighlightedReservationTableItem,
} from "./reservationsData";
import "./index.scss";
import { ReservationState, useReservationsColumns } from "./reservationColumns";
import { ReservationsPageEnumsQuery } from "@relay-generated/ReservationsPageEnumsQuery.graphql";
import { reservationsPageEnumsQuery } from "./graphql/ReservationsPageEnumsQuery";
import { ColumnSort, SortingState } from "@tanstack/react-table";
import reservationsQuery, {
  ReservationsOverviewColumns,
  ReservationsQuery,
} from "@relay-generated/ReservationsQuery.graphql";
import { reservationsFragment } from "./graphql/ReservationsFragment";
import { ReservationsTable } from "./ReservationsTable";
import { ReservationsFragment$key } from "@relay-generated/ReservationsFragment.graphql";
import { useNavigate } from "react-router-dom";

function compareArrays<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return (
    arr1.every(item => arr2.includes(item)) &&
    arr2.every(item => arr1.includes(item))
  );
}

export const ReservationsContext = createContext<{
  columnFilters: ActiveColumnFilter[];
  setColumnFilters: (filters: ActiveColumnFilter[]) => void;
  sorting: SortingState;
  setSorting: (sorting: SortingState) => void;
  selection?: RowSelection<HighlightedReservationTableItem>;
  setSelection: (item?: RowSelection<HighlightedReservationTableItem>) => void;
} | null>(null);

export function ReservationsPage() {
  const [queryRef, loadQuery] = useQueryLoader<ReservationsPageEnumsQuery>(
    reservationsPageEnumsQuery,
  );

  useEffect(() => {
    loadQuery({});
  }, []);

  return queryRef && <LoadedReservationsPage pageEnumsQueryRef={queryRef} />;
}

// FIXME opravit problikávání - resp překreslovat jen komponenty závislé na datech?

function LoadedReservationsPage({
  pageEnumsQueryRef,
}: {
  pageEnumsQueryRef: PreloadedQuery<ReservationsPageEnumsQuery>;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const editReservation = useCallback(
    (item: HighlightedReservationTableItem) => {
      navigate(item.reservationUid);
    },
    [],
  );

  const newReservation = useCallback(() => {
    navigate("new");
  }, []);

  const pageEnumsData = usePreloadedQuery(
    reservationsPageEnumsQuery,
    pageEnumsQueryRef,
  );
  const columns = useReservationsColumns(pageEnumsData, editReservation);

  const states = useMemo(() => {
    const stateColumn = columns.find(c => c.id === "status");
    return (stateColumn?.meta?.filter as SelectFilterDef<ReservationState>)
      ?.items;
  }, [columns]);

  // FIXME Pokud načtu stránku přes F5, první jazyk je angličtina a ne čeština

  const [columnFilters, setColumnFilters] = useState<ActiveColumnFilter[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selection, setSelection] =
    useState<RowSelection<HighlightedReservationTableItem>>();

  const extendedFilters = useExtendedFilters(columns, columnFilters);

  const groupedState = useMemo(() => {
    const statusFilter = columnFilters.find(f => f.id === "status");
    const selectedStates =
      (statusFilter?.value as ReservationState[] | undefined)?.map(i => i.id) ??
      [];
    return groupedStateFilters.find(f =>
      compareArrays(
        f.states ?? [],
        selectedStates.map(s => s),
      ),
    );
  }, [columnFilters]);

  const stayDate = useMemo(() => {
    const checkInFilter = columnFilters.find(f => f.id === "checkIn");
    const checkOutFilter = columnFilters.find(f => f.id === "checkOut");

    const checkIn = checkInFilter?.value as PartialDateRange | undefined;
    const checkOut = checkOutFilter?.value as PartialDateRange | undefined;

    if (
      checkIn?.[1] &&
      checkOut?.[0] &&
      dayjs(checkIn[1]).isSame(checkOut[0], "day")
    ) {
      return checkIn[1];
    } else {
      return undefined;
    }
  }, [columnFilters]);

  const [reservationsQueryRef, loadQuery] =
    useQueryLoader<ReservationsQuery>(reservationsQuery);

  const setFilter = useCallback(
    (id: string, value: unknown) => {
      reservationsQueryRef?.dispose();
      setColumnFilters(f => {
        const newFilters = f.filter(i => i.id !== id);
        if (value) {
          newFilters.push({
            id,
            value,
          });
        }
        return newFilters;
      });
    },
    [setColumnFilters],
  );

  // TODO tohle bude obecné a ne jen pro rezervace
  useEffect(() => {
    const sort: ColumnSort | undefined = sorting?.[0];
    loadQuery(
      {
        filters: {
          ...Object.fromEntries(
            extendedFilters
              .filter(f => f.apiValue)
              .map(f => [f.apiPropertyName, f.apiValue]),
          ),
          search,
        },
        // API supports only single column sorting
        orderBy: sort
          ? {
              order: sort.desc ? "DESC" : "ASC",
              column: sort.id as ReservationsOverviewColumns,
            }
          : undefined,
      },
      { fetchPolicy: "store-and-network" },
    );
  }, [extendedFilters, search, sorting]);

  return (
    <ReservationsContext.Provider
      value={{
        columnFilters,
        setColumnFilters,
        sorting,
        setSorting,
        selection,
        setSelection,
      }}>
      <Page variant="compact">
        <FlowLayout>
          <div className="page-title Font--section-heading">
            {t("reservations_overview")}
          </div>
          <FlowGroup>
            <div className="Font--regular-acc">{t("active_filter")}:</div>
            {columnFilters.length > 0 ? (
              <>
                <Tags>
                  {extendedFilters.map(c => (
                    <Tag
                      key={c.id}
                      onDelete={() => {
                        setColumnFilters(f => f.filter(i => i.id !== c.id));
                      }}>
                      {c.label}
                    </Tag>
                  ))}
                </Tags>
                <IconButton
                  onClick={() => setColumnFilters([])}
                  tooltip={t("reset_all_filters_tooltip")}
                  icon={ArrowCounterclockwise}
                />
              </>
            ) : (
              <span
                style={{
                  padding: 4,
                  fontSize: 12 /*FIXME stejná velikost jako tagy, aby layout neskákal, když se mění filtry*/,
                }}>
                {t("none")}
              </span>
            )}
          </FlowGroup>
          {reservationsQueryRef && (
            <TotalCount queryRef={reservationsQueryRef} />
          )}
        </FlowLayout>
        <FlowLayout style={{ marginBottom: 20 }}>
          <SearchField
            value={search}
            onChange={setSearch}
            label={t("search")}
            helperText="Zadejte číslo, název, objednávku, nebo číslo transakce rezervace."
          />
          <ToggleButtonGroup
            name="grouped-state"
            label="&nbsp;" // TODO lépe
            value={groupedState?.id}
            onChange={g => {
              const statesIds = groupedStateFilters.find(
                i => i.id === g,
              )?.states;
              setFilter(
                "status",
                statesIds
                  ? states.filter(s => statesIds.includes(s.id))
                  : undefined,
              );
            }}>
            <ToggleItem value="all">{t("all")}</ToggleItem>
            <ToggleItem value="accommodated">{t("accommodated")}</ToggleItem>
            <ToggleItem value="valid">{t("valid")}</ToggleItem>
            <ToggleItem value="cancelled">{t("cancelled")}</ToggleItem>
          </ToggleButtonGroup>

          <DateField
            label={t("stay")}
            style={{ minWidth: 160 }}
            value={stayDate}
            onChange={d => {
              setFilter("checkIn", d ? [undefined, d] : undefined);
              setFilter("checkOut", d ? [d, undefined] : undefined);
            }}
          />

          <DateRangeField
            label={t("created")}
            showPredefinedDates
            multiMonth
            value={
              columnFilters.find(i => i.id === "createdAt")?.value as
                | FullDateRange
                | undefined
            }
            onChange={v => setFilter("createdAt", v)}
          />

          <FormItem label="&nbsp;">
            <Button onClick={() => newReservation()} icon={<PlusCircle />}>
              {t("new_reservation")}
            </Button>
          </FormItem>
        </FlowLayout>

        <Suspense>
          {reservationsQueryRef && (
            <ReservationsTable
              queryRef={reservationsQueryRef}
              search={search}
              columns={columns}
              editReservation={editReservation}
            />
          )}
        </Suspense>
      </Page>
    </ReservationsContext.Provider>
  );
}

function TotalCount({
  queryRef,
}: {
  queryRef: PreloadedQuery<ReservationsQuery>;
}) {
  const { t, tp } = useTranslation();

  const preloadedQuery = usePreloadedQuery<ReservationsQuery>(
    reservationsQuery,
    queryRef,
  );
  const { data } = usePaginationFragment<
    ReservationsQuery,
    ReservationsFragment$key
  >(reservationsFragment, preloadedQuery);

  const count = data.reservationsOverviewList.totalCount;
  return (
    <FlowGroup>
      <div className="Font--regular">
        {t("active_filters_result_row_count")}:
      </div>
      <div className="Font--regular-big">
        {count} {tp("reservations_count", count)}
      </div>
    </FlowGroup>
  );
}
