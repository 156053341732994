import { ColumnDef } from "@tanstack/react-table";
import { IconButton, Table, useTranslation } from "h11-client-component-lib";
import { useMemo } from "react";
import { Eye } from "react-bootstrap-icons";
import dayjs from "dayjs";

export function HistoryPanel() {
  const { t } = useTranslation();

  const data = [
    {
      time: dayjs(new Date()).format("DD.MM.YYYY HH:mm"),
      user: "John Doe",
      action: "Created reservation",
    },
    {
      time: dayjs(new Date()).format("DD.MM.YYYY HH:mm"),
      user: "John Doe",
      action: "Created reservation",
    },
    {
      time: dayjs(new Date()).format("DD.MM.YYYY HH:mm"),
      user: "John Doe",
      action: "Created reservation",
    },
  ];

  const columns: ColumnDef<(typeof data)[number]>[] = useMemo(
    () => [
      {
        accessorKey: "time",
        header: t("time"),
      },
      {
        accessorKey: "user",
        header: t("user"),
      },
      {
        accessorKey: "action",
        header: t("action"),
      },
      {
        header: t("detail"),
        cell: row => (
          <IconButton
            onClick={() => alert("TODO")}
            tooltip={t("show_history_detail_tooltip")}
            icon={Eye}
          />
        ),
        meta: {
          center: true,
          hug: true,
        },
        enableHiding: false,
        enableSorting: false,
      },
    ],
    [],
  );
  return (
    <Table
      tableId="reservation-history"
      columns={columns}
      data={data}
      fullWidth
    />
  );
}
