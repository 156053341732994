import { DUMMY_SERVICES } from "./ServicesTable";
import { SelectField, Switch, TextField } from "h11-client-component-lib";

export const ServiceRow = ({
  service,
}: {
  service: (typeof DUMMY_SERVICES)[number];
}) => {
  return (
    <tr>
      <td>
        <SelectField
          variant="inline"
          items={["parking"]}
          valueToString={i => i}
          itemIdExtractor={i => i}
          value={service.service}
          onChange={() => {}}
        />
      </td>
      <td>
        <SelectField
          variant="inline"
          items={["selected_days"]}
          valueToString={i => i}
          itemIdExtractor={i => i}
          value={service.burdeningFrequency}
          onChange={() => {}}
        />
      </td>
      <td>
        <SelectField
          variant="inline"
          items={["room"]}
          valueToString={i => i}
          itemIdExtractor={i => i}
          value={service.burden}
          onChange={() => {}}
        />
      </td>
      <td className="right">
        {/* TODO NumberField */}
        <TextField
          variant="inline"
          value={service.unitPrice.toString()}
          onChange={() => {}}
        />
      </td>
      <td>
        <SelectField
          variant="inline"
          items={["CZK", "EUR"]}
          valueToString={i => i}
          itemIdExtractor={i => i}
          value={service.currency}
          onChange={() => {}}
        />
      </td>
      <td className="right">
        {/* TODO NumberField */}
        <TextField
          variant="inline"
          value={service.totalPrice.toString()}
          onChange={() => {}}
        />
      </td>
      <td>
        <div className="center">
          <Switch
            id={`service-${service.id}-group-burdening-switch`}
            checked={service.groupBurdening}
            onChange={() => {}}
          />
        </div>
      </td>
    </tr>
  );
};
