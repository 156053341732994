import { createContext, useContext } from "react";
import {
  GuestFormData,
  ReservationEnums,
  RoomFormData,
} from "./reservationData";

export const ReservationFormContext = createContext<{
  enums: ReservationEnums;
  nextGroupTempUid: () => string;
  nextRoomTempUid: () => string;
  nextGuestTempUid: () => string;
  createDefaultGuest: (childCategoryId: number | null) => GuestFormData;
  createDefaultRoom: () => RoomFormData;
} | null>(null);

export const useReservationContext = () => {
  const context = useContext(ReservationFormContext);
  if (context === null) {
    throw new Error("No reservation context");
  }
  return context;
};
