import { HighlightedReservationTableItem } from "./reservationsData";
import { Check2, Pencil, PersonCheck, XLg } from "react-bootstrap-icons";
import { ColumnDef } from "@tanstack/react-table";
import {
  Icon,
  IconButton,
  SelectFilterDef,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { ReservationsPageEnumsQuery$data } from "@relay-generated/ReservationsPageEnumsQuery.graphql";
import { UserAvatar } from "@shared/ui/UserAvatar";
import dayjs from "dayjs";
import {
  ReservationGroups,
  ReservationPrepayment,
  ReservationRefundable,
  ReservationRooming,
  ReservationStatus,
} from "@relay-generated/ReservationsQuery.graphql";
import { clsx } from "clsx";

interface SelectItem<T> {
  id: T;
  label: string;
}

type BasicSelectItem = SelectItem<number | string>;

// Zavádějící název
export interface ReservationState {
  id: ReservationStatus;
  label: string;
  icon: ReactElement;
}

function CheckIcon({ green, tooltip }: { green: boolean; tooltip: string }) {
  // TODO možná zobecnit i s tím, co je v UsersTable, tlačítky a podobně?
  return (
    <div className="center">
      <Icon
        icon={Check2}
        tooltip={tooltip}
        className={clsx("check-icon", { green })}
      />
    </div>
  );
}

export function useReservationsColumns(
  data: ReservationsPageEnumsQuery$data,
  editReservation: (item: HighlightedReservationTableItem) => void,
): ColumnDef<HighlightedReservationTableItem>[] {
  const { t, tp } = useTranslation();

  // TODO asi dpo redux?, pravděpodobně se bude používat i jinde
  const states = useMemo<Map<ReservationStatus, ReservationState>>(
    () =>
      new Map(
        [
          {
            id: "CANCELLED" as ReservationStatus,
            label: t("cancelled"),
            icon: <XLg className="reservation-state cancelled" />,
          },
          {
            id: "TENTATIVE" as ReservationStatus,
            label: t("tentative"),
            icon: <div className="reservation-state tentative" />,
          },
          {
            id: "FIX" as ReservationStatus,
            label: t("fix"),
            icon: <div className="reservation-state fix" />,
          },
          {
            id: "UNCONFIRMED" as ReservationStatus,
            label: t("unconfirmed"),
            icon: <div className="reservation-state unconfirmed" />,
          },
          {
            id: "WAITING" as ReservationStatus,
            label: t("waiting"),
            icon: <div className="reservation-state waiting" />,
          },

          // FIXME, zatím jen provizorní blbost, než bude v API
          {
            id: "ACCOMMODATED" as ReservationStatus,
            label: "Ubytovaná",
            icon: <div className="reservation-state accommodated" />,
          },
          {
            id: "PARTIALLY_ACCOMMODATED" as ReservationStatus,
            label: "Částečně ubytovaná",
            icon: <div className="reservation-state partially-accommodated" />,
          },
        ].map(i => [i.id, i]),
      ),
    [t],
  );

  const stateFilter: SelectFilterDef<ReservationState> = {
    items: Array.from(states.values()),
    selectAllLabel: t("all_reservation_states"),
    itemIdExtractor: i => i.id,
    renderValue: i => (
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <span>{i.icon}</span>
        <span>{i.label}</span>
      </div>
    ),
  };

  const isGroupFilter: SelectFilterDef<SelectItem<ReservationGroups>> = {
    items: [
      {
        id: "GROUP",
        label: t("groups"),
      },
      {
        id: "SINGLE",
        label: t("individuals"),
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
    //treatAsSingleWorkaround: true,
  };

  const tagFilter: SelectFilterDef<BasicSelectItem> = {
    items:
      data.reservationTagList?.map(t => ({ id: t._id, label: t.title })) ?? [],
    selectAllLabel: t("all_tags"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const roomTypeFilter: SelectFilterDef<BasicSelectItem> = {
    items: data.spaceTypeList?.map(t => ({ id: t._id!, label: t.code! })) ?? [],
    selectAllLabel: t("all_types"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const sourceFilter: SelectFilterDef<BasicSelectItem> = {
    items:
      data.reservationSourceList?.map(t => ({ id: t._id, label: t.code })) ??
      [],
    selectAllLabel: t("all_sources"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const prepaymentFilter: SelectFilterDef<SelectItem<ReservationPrepayment>> = {
    items: [
      {
        id: "PROFORMA",
        label: t("proforma"),
      },
      {
        id: "PROFORMA_OVERDUE",
        label: t("proforma_overdue"),
      },
      {
        id: "PAID",
        label: t("paid"),
      },
      {
        id: "NO_PREPAYMENT",
        label: t("no_prepayment"),
      },
      {
        id: "UNPAID",
        label: t("unpaid"),
      },
    ],
    selectAllLabel: t("all_prepayments"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const roomingFilter: SelectFilterDef<SelectItem<ReservationRooming>> = {
    items: [
      {
        id: "FILLED",
        label: t("filled_rooming"),
      },
      {
        id: "INCOMPLETE",
        label: t("incomplete_rooming"),
      },
      {
        id: "EMPTY",
        label: t("empty_rooming"),
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const guestCodeFilter: SelectFilterDef<BasicSelectItem> = {
    items: data.guestCodeList?.map(t => ({ id: t._id, label: t.code })) ?? [],
    selectAllLabel: t("all_guest_codes"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const salesCodeFilter: SelectFilterDef<BasicSelectItem> = {
    items: data.saleCodeList?.map(t => ({ id: t._id, label: t.code })) ?? [],
    selectAllLabel: t("all_sales_codes"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const nonRefundableFilter: SelectFilterDef<
    SelectItem<ReservationRefundable>
  > = {
    items: [
      {
        id: "NON_REFUNDABLE",
        label: t("non_refundable"),
      },
      {
        id: "REFUNDABLE",
        label: t("refundable"),
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const authorFilter: SelectFilterDef<
    ReservationsPageEnumsQuery$data["userList"][number]
  > = {
    items: [...data.userList],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.userUid,
    renderValue: i => (
      <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <UserAvatar user={i} size="small" />
        {i.firstName + " " + i.lastName}
      </span>
    ),
  };

  const paymentMethodFilter: SelectFilterDef<BasicSelectItem> = {
    items: [
      {
        id: "card",
        label: "Kartou TODO",
      },
      {
        id: "invoice",
        label: "Faktura TODO",
      },
    ],
    selectAllLabel: t("all"),
    itemIdExtractor: i => i.id,
    renderValue: i => <span>{i.label}</span>,
  };

  const basicColumn = useCallback(
    <TSelectItem,>(
      key: keyof HighlightedReservationTableItem,
      title: string,
      filter?: SelectFilterDef<TSelectItem> | "date",
    ): ColumnDef<HighlightedReservationTableItem> => {
      return {
        id: key, // id:key mainly for filter to be able to obtain filtered column label
        accessorKey: key,
        enableColumnFilter: !!filter,
        filterFn: filter ? "arrIncludesSome" : undefined, // FIXME jinak pro datumy
        header: title,
        meta: {
          filter: filter,
        },
        // For highlighting to work
        cell: i => i.getValue(),
      };
    },
    [],
  );

  return useMemo(
    () => [
      {
        id: "edit-column",
        enableHiding: false,
        enableSorting: false,
        cell: i => (
          <IconButton
            icon={Pencil}
            onClick={e => {
              e.stopPropagation();
              // FIXME i na double click
              editReservation(i.row.original);
            }}
            tooltip={t("edit_reservation_tooltip")}
          />
        ),
      },
      {
        ...basicColumn("status", t("state"), stateFilter),
        enableSorting: false,
        cell: c => {
          const state = states.get(c.getValue() as ReservationStatus);
          return (
            state && <Tooltipped tooltip={state.label}>{state.icon}</Tooltipped>
          );
        },
        meta: {
          center: true,
        },
      },
      {
        ...basicColumn("group", t("is_group_abbr"), isGroupFilter),
        cell: i =>
          i.getValue() === "GROUP" && (
            <CheckIcon green tooltip={t("is_group_tooltip")} />
          ),
      },
      {
        ...basicColumn("tags", t("tags"), tagFilter),
        enableSorting: false,
        cell: i => {
          return (
            <div className="tags" style={{ display: "flex", gap: 6 }}>
              {(i.getValue() as number[]).map(tagId => {
                const tag = data.reservationTagList?.find(t => t._id === tagId);
                return (
                  // FIXME sjednotit s Tags / Tag které jsou nahoře v aktivních filtrech
                  tag && (
                    <div
                      key={tagId}
                      className="tag"
                      style={{
                        backgroundColor: tag.color ?? undefined,
                        color: "white", // TODO potom podle kontrastu
                        padding: "2px 6px",
                        margin: "-2px 0", // So it doesn't influence the rows height
                        borderRadius: 4,
                      }}>
                      {tag.title}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
      },
      {
        ...basicColumn(
          "numberHighlighted",
          t("reservation_number_abbr"),
          undefined,
        ),
        id: "number",
      },
      {
        ...basicColumn("nameHighlighted", t("res_name"), undefined),
        id: "name",
      },
      basicColumn("roomType", t("room_type_abbr"), roomTypeFilter),
      {
        ...basicColumn("roomCount", t("room_count")),
        enableSorting: false,
      },
      {
        ...basicColumn("guestsCount", t("people_count")),
        enableSorting: false,
      },
      {
        ...basicColumn("checkIn", t("check_in"), "date"),
        cell: i =>
          i.getValue()
            ? // FIXME zobecnit dle vybrané lokalizace
              dayjs(i.getValue() as string).format("DD.MM.YYYY")
            : undefined,
      },
      {
        ...basicColumn("checkOut", t("check_out"), "date"),
        cell: i =>
          i.getValue()
            ? dayjs(i.getValue() as string).format("DD.MM.YYYY")
            : undefined,
      },
      basicColumn("source", t("source"), sourceFilter),
      {
        ...basicColumn("price", t("price")),
        enableSorting: false,
      },
      {
        ...basicColumn("prepayment", t("prepayment"), prepaymentFilter),
        cell: i => i.getValue(), // TODO ikona
      },
      {
        ...basicColumn("rooming", t("rooming"), roomingFilter),
        cell: i => {
          let icon: ReactNode;
          switch (i.getValue()) {
            case "FILLED":
              // TODO Ikona ve Figmě odpovídá něčemu mezi PersonCheck a PersonCheckFill
              icon = <PersonCheck style={{ color: "rgba(0, 172, 17, 1)" }} />;
              break;
            case "INCOMPLETE":
              icon = <PersonCheck />;
              break;
            default:
              return null;
          }

          return icon;
        },
        meta: {
          center: true,
        },
      },
      {
        ...basicColumn(
          "orderNumberHighlighted",
          t("order_number_abbr"),
          undefined,
        ),
        id: "orderNumber",
      },
      basicColumn("country", tp("country", 1)),
      {
        ...basicColumn("optionDate", t("option_date"), "date"),
        cell: i =>
          i.getValue()
            ? dayjs(i.getValue() as string).format("DD.MM.YYYY")
            : undefined,
      },
      {
        ...basicColumn("guestCode", t("guest_code"), guestCodeFilter),
        enableSorting: false,
      },
      {
        ...basicColumn("salesCode", t("sales_code"), salesCodeFilter),
        enableSorting: false,
      },
      basicColumn("note", t("note")),
      {
        ...basicColumn(
          "nonRefundable",
          t("non_refundable"),
          nonRefundableFilter,
        ),
        cell: i =>
          i.getValue() && (
            <CheckIcon green tooltip={t("non_refundable_tooltip")} />
          ),
      },
      {
        ...basicColumn("author", t("author"), authorFilter),
        enableSorting: false,
        cell: cell => {
          const userUid = cell.row.original.author;
          const user = data.userList?.find(u => u.userUid === userUid);
          return user && <span>{user.firstName + " " + user.lastName}</span>;
        },
      },
      {
        ...basicColumn("createdAt", t("created"), "date"),
        cell: i =>
          i.getValue()
            ? dayjs(i.getValue() as string).format("DD.MM.YYYY")
            : undefined,
      },
      {
        ...basicColumn("cancelledAt", t("canceled"), "date"),
        cell: i =>
          i.getValue() && <CheckIcon green tooltip={t("cancelled_tooltip")} />,
      },
      basicColumn("transactionNumber", t("transaction_number")),
      basicColumn(
        "paymentMethod",
        t("payment_method_abbr"),
        paymentMethodFilter,
      ),
      {
        ...basicColumn("attachment", t("attachments")),
        enableSorting: false,
      },
    ],
    [data],
  );
}
