import { graphql } from "react-relay";

export const reservationDetailFragment = graphql`
  fragment ReservationDetailFragment on Query
  @argumentDefinitions(uid: { type: "UUID!" }) {
    reservationGet(reservationUid: $uid) {
      uid
      header {
        note
        status
        guestCodeId
        country
        preferredPaymentMethodId
        referenceNumber
        reservationName
        sourceId
      }
      checkedInRooms {
        roomId
        guests {
          uid
          firstName
          lastName
          birthDate
          citizenshipCountry
          childCategoryId
          checkinDate
          note
        }
      }
      groupRooms {
        uid
        roomTypeId
        checkinDate
        checkoutDate
        rooms {
          uid
          roomId
          guests {
            uid
            firstName
            lastName
            birthDate
            citizenshipCountry
            childCategoryId
            note
          }
        }
      }
    }
  }
`;
