/**
 * @generated SignedSource<<26303292063d7af1fb71234c51c124d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReservationDetailFragment$data = {
  readonly reservationGet: {
    readonly checkedInRooms: ReadonlyArray<{
      readonly guests: ReadonlyArray<{
        readonly birthDate: string | null | undefined;
        readonly checkinDate: string | null | undefined;
        readonly childCategoryId: number | null | undefined;
        readonly citizenshipCountry: string | null | undefined;
        readonly firstName: string | null | undefined;
        readonly lastName: string | null | undefined;
        readonly note: string | null | undefined;
        readonly uid: string;
      }>;
      readonly roomId: number | null | undefined;
    }>;
    readonly groupRooms: ReadonlyArray<{
      readonly checkinDate: string;
      readonly checkoutDate: string;
      readonly roomTypeId: number;
      readonly rooms: ReadonlyArray<{
        readonly guests: ReadonlyArray<{
          readonly birthDate: string | null | undefined;
          readonly childCategoryId: number | null | undefined;
          readonly citizenshipCountry: string | null | undefined;
          readonly firstName: string | null | undefined;
          readonly lastName: string | null | undefined;
          readonly note: string | null | undefined;
          readonly uid: string;
        }>;
        readonly roomId: number | null | undefined;
        readonly uid: string;
      }>;
      readonly uid: string;
    }>;
    readonly header: {
      readonly country: string | null | undefined;
      readonly guestCodeId: number | null | undefined;
      readonly note: string | null | undefined;
      readonly preferredPaymentMethodId: number | null | undefined;
      readonly referenceNumber: string | null | undefined;
      readonly reservationName: any;
      readonly sourceId: number;
      readonly status: string;
    } | null | undefined;
    readonly uid: string;
  } | null | undefined;
  readonly " $fragmentType": "ReservationDetailFragment";
};
export type ReservationDetailFragment$key = {
  readonly " $data"?: ReservationDetailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReservationDetailFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "citizenshipCountry",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "childCategoryId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkinDate",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "uid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReservationDetailFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "reservationUid",
          "variableName": "uid"
        }
      ],
      "concreteType": "Reservation",
      "kind": "LinkedField",
      "name": "reservationGet",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ReservationHeader",
          "kind": "LinkedField",
          "name": "header",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "guestCodeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferredPaymentMethodId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "referenceNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reservationName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sourceId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReservationRoom",
          "kind": "LinkedField",
          "name": "checkedInRooms",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ReservationGuest",
              "kind": "LinkedField",
              "name": "guests",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupRooms",
          "kind": "LinkedField",
          "name": "groupRooms",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roomTypeId",
              "storageKey": null
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "checkoutDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GroupedReservationRoom",
              "kind": "LinkedField",
              "name": "rooms",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReservationGuest",
                  "kind": "LinkedField",
                  "name": "guests",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "94a6c205e28fc9deac7b30e5d275e07e";

export default node;
