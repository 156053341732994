import { useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { UserTableItem } from "./userData";
import { useTranslation } from "react-i18next";
import { useLoggedUser } from "@store";
import { usersQuery } from "./graphql/UsersQuery";
import { UserAvatar } from "@shared/ui/UserAvatar";
import { ColumnDef } from "@tanstack/react-table";
import { Check2, InfoCircle, Pencil, Trash } from "react-bootstrap-icons";
import dayjs from "dayjs";
import { UsersQuery } from "@relay-generated/UsersQuery.graphql";
import {
  Icon,
  IconButton,
  Switch,
  Table,
  useHighlightAndFilter,
} from "h11-client-component-lib";

export function UsersTable({
  onRowAction,
  queryRef,
  search,
}: {
  queryRef: PreloadedQuery<UsersQuery>;
  onRowAction: (
    action: "delete" | "edit" | "activate" | "deactivate",
    user: UserTableItem,
  ) => void;
  search: string;
}) {
  const { t } = useTranslation();
  const loggedUser = useLoggedUser();

  const query = usePreloadedQuery(usersQuery, queryRef);

  // TODO bez vykřičníku
  const users = useMemo(
    () =>
      [
        ...query.userList.map(u => ({
          ...u,
          avatarComponent: <UserAvatar user={u} size="small" noBorder />,
        })),
      ]!,
    [query],
  );

  const filteredUsers = useHighlightAndFilter(
    users,
    search,
    "firstName",
    "lastName",
    "code",
  );

  // FIXME UserRole, pokud je instance of Node, by měl mít stringové id (typ ID) - ověřit s Brychem?

  // TODO Nastává mi tady problém, když mám všechny properties any
  const columns: ColumnDef<(typeof filteredUsers)[number]>[] = [
    {
      enableHiding: false,
      accessorKey: "avatarComponent",
      header: "",
      meta: {
        cellClassName: "no-vertical-padding",
      },
      cell: ({ row: { original } }) => original.avatarComponent,
    },
    {
      accessorKey: "firstName",
      header: t("name"),
      sortingFn: "alphanumeric",
      cell: p => p.row.original.firstNameHighlighted,
    },
    {
      accessorKey: "lastName",
      header: t("surname"),
      sortingFn: "alphanumeric",
      cell: p => p.row.original.lastNameHighlighted,
    },
    {
      accessorKey: "code",
      header: t("code"),
      sortingFn: "alphanumeric",
      cell: p => p.row.original.codeHighlighted,
    },
    {
      accessorKey: "userRoles",
      header: t("user_roles_short"),
      cell: p =>
        (p.getValue() as { name: string }[]).map(r => r.name).join(", ") || "–",
    },
    {
      accessorKey: "validTo",
      header: t("valid_to"),
      cell: p => {
        const date = dayjs(p.getValue() as string);
        return (
          // TODO barvu z knihovny - $error2
          // TODO class, ne style
          // FIXME formát datumu dle lokalizace
          <span
            style={
              date.isBefore(dayjs(), "day")
                ? { color: "rgb(204,0,0)" }
                : undefined
            }>
            {date.format("DD.MM.YYYY")}
          </span>
        );
      },
    },
    {
      accessorKey: "interactiveLogin",
      header: t("technical_user"),
      cell: p =>
        !p.getValue() ? (
          <div className="center">
            <Icon icon={Check2} tooltip={t("technical_user_tooltip")} />
          </div>
        ) : null,
    },
    {
      id: "info",
      enableHiding: false,
      header: () => <div className="center">{t("info")}</div>,
      enableSorting: false,
      cell: () => (
        <div className="center">
          <IconButton
            onClick={() => alert("TODO")}
            tooltip={t("show_user_info_tooltip")}
            icon={InfoCircle}
          />
        </div>
      ),
    },
    {
      accessorKey: "active",
      enableHiding: false,
      header: () => <div className="center">{t("active")}</div>,
      meta: {
        cellClassName: "ignore-inactive, no-vertical-padding",
      },
      cell: p => (
        <div className="center">
          <Switch
            id={`${p.row.original.userUid}-active`}
            onChange={checked => {
              onRowAction(checked ? "activate" : "deactivate", p.row.original);
            }}
            disabled={p.row.original.userUid === loggedUser?.userUid}
            checked={p.row.original.active}
          />
        </div>
      ),
    },
    {
      id: "edit",
      enableHiding: false,
      header: () => <div className="center">{t("edit")}</div>,
      cell: c => (
        <div className="center">
          <IconButton
            onClick={() => onRowAction("edit", c.row.original)}
            tooltip={t("edit_user_tooltip")}
            icon={Pencil}
          />
        </div>
      ),
    },
    {
      id: "delete",
      enableHiding: false,
      header: () => <div className="center">{t("delete")}</div>,
      cell: p => (
        <div className="center">
          <IconButton
            onClick={() => onRowAction("delete", p.row.original)}
            disabled={p.row.original.userUid === loggedUser?.userUid}
            tooltip={t("delete_user_tooltip")}
            icon={Trash}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      tableId="users"
      columns={columns}
      data={filteredUsers}
      defaultSorting={{ id: "firstName", desc: false }}
      trProps={d => (!d.active ? { className: "inactive" } : {})}
    />
  );
}
