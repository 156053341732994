import { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { ReservationFormData, RoomGroupFormData } from "./reservationData";
import { useFormContext, useWatch } from "react-hook-form";

export const useGuestsCount = () => {
  const { control } = useFormContext<ReservationFormData>();

  // FIXME ověřit, jestli stačí naslouchat změnám groups, protože potřebuji přepočíst i při změně hostů ve všech skupinách
  // Původně bylo řešené přes watch se subscription, což je blbé, protože watch je zlý! useWatch je hodný!
  const groups = useWatch({ control, name: "groups" });

  const adultsCount = useMemo(
    // TODO zatím všichni hosté, ale mělo by to být zvlášť dospělí a děti
    () => {
      return groups
        ?.flatMap(g => g?.rooms)
        ?.reduce((acc, room) => acc + (room?.guests?.length ?? 0), 0);
    },
    [groups],
  );

  const childrenCount = 0;

  return {
    adultsCount,
    childrenCount,
    totalCount:
      adultsCount || childrenCount
        ? (adultsCount ?? 0) + (childrenCount ?? 0)
        : undefined,
  };
};

export const useNightsCount = () => {
  const { control } = useFormContext<ReservationFormData>();

  const groups = useWatch({ control, name: "groups" });

  return useMemo(() => {
    console.debug("Groups changed");
    const minCheckinDate = groups?.reduce((acc: Dayjs | undefined, group) => {
      if (
        group &&
        group.checkinDate &&
        (!acc || dayjs(group.checkinDate).isBefore(acc))
      ) {
        return dayjs(group.checkinDate);
      }
      return acc;
    }, undefined);
    const maxCheckoutDate = groups?.reduce((acc: Dayjs | undefined, group) => {
      if (
        group &&
        group.checkoutDate &&
        (!acc || dayjs(group.checkoutDate).isAfter(acc))
      ) {
        return dayjs(group.checkoutDate);
      }
      return acc;
    }, undefined);
    if (minCheckinDate && maxCheckoutDate) {
      return maxCheckoutDate.diff(minCheckinDate, "day");
    } else {
      return undefined;
    }
  }, [groups]);
};

export const useReservationGroupDates = (group: RoomGroupFormData) => {
  return useMemo(() => {
    const ret: Dayjs[] = [];
    if (group.checkinDate && group.checkoutDate) {
      let date = dayjs(group.checkinDate);
      const checkoutDate = dayjs(group.checkoutDate);
      while (date.isBefore(checkoutDate)) {
        ret.push(date);
        date = date.add(1, "day");
      }
    }
    return ret;
  }, [group.checkinDate, group.checkoutDate]);
};
