import { useFormContext, useWatch } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";
import { useTranslation } from "h11-client-component-lib";
import "./PricesTable.scss";
import { useReservationGroupDates } from "../../reservationHooks";

export const PricesTable = ({ groupIndex }: { groupIndex: number }) => {
  // Ordinary table is used, because Table component would be a overhead
  // TODO implement ordinary table also as a component? i. e. Table and rename the current Table to DataTable?

  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  const group = useWatch({ control, name: `groups.${groupIndex}` });

  const dates = useReservationGroupDates(group);

  return (
    <div className="Table PricesTable">
      <table>
        <thead>
          <tr>
            <th></th>
            {dates.map(date => (
              <th key={date.unix()}>{date.format("dd DD.MM.")}</th>
            ))}
            <th>{t("total")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("accommodation")}</td>
            {dates.map(date => (
              <td key={date.unix()}>{1000}</td>
            ))}
            <td>{dates.length * 1000}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
