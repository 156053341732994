import {
  FlowLayout,
  FormFieldAligner,
  SelectField,
  Tag,
  Tags,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import {
  OriginData,
  ReservationFormData,
  SourceData,
} from "../../reservationData";
import { FormSelectField } from "../../../to_lib/FormSelectField";
import { useReservationContext } from "../../ReservationFormContext";
import { FormSwitchField } from "../../../to_lib/FormSwitchField";
import { useMemo } from "react";
import { Plus } from "react-bootstrap-icons";

export function OverviewPanel() {
  const { t } = useTranslation();
  const {
    enums: { sources, origins },
  } = useReservationContext();

  const reservationStatuses = useMemo(
    () => [
      {
        id: "waiting",
        label: t("waiting"),
      },
      {
        id: "confirmed",
        label: t("confirmed"),
      },
      {
        id: "unconfirmed",
        label: t("unconfirmed"),
      },
      {
        id: "tentative",
        label: t("tentative"),
      },
      {
        id: "fix",
        label: t("fix"),
      },
      {
        id: "cancelled",
        label: t("cancelled"),
      },
    ],
    [],
  );

  return (
    <FlowLayout variant="spacious">
      {/*TODO možná vždy předávat control z kterého si potom field vezme i typ dat. Nebudu muset typy specifikovat a TS si je odvodí*/}
      <FormSelectField<ReservationFormData, SourceData>
        items={sources}
        valueToString={value => value.name}
        itemIdExtractor={v => v._id}
        field="header.sourceId"
        label={t("source")}
      />
      <FormSelectField<ReservationFormData, OriginData>
        items={origins}
        valueToString={value => value.name}
        itemIdExtractor={v => v._id}
        field="header.originId"
        label={t("origin")}
      />
      <FormFieldAligner>
        <FormSwitchField<ReservationFormData>
          id="is-group-switch"
          field="header.isGroup"
          label={t("group")}
        />
      </FormFieldAligner>
      <FormFieldAligner>
        <FormSwitchField<ReservationFormData>
          id="non-refundable-switch"
          field="header.nonRefundable"
          label={t("non_refundable_abbreviation")}
        />
      </FormFieldAligner>
      <FormSelectField<
        ReservationFormData,
        (typeof reservationStatuses)[number]
      >
        items={reservationStatuses}
        valueToString={value => value.id}
        itemIdExtractor={v => v.label}
        field="header.status"
        label={t("reservation_status")}
      />
      {/*FIXME tariff & segment nejsou v API; potom změnit na FormSelectField*/}
      <SelectField<string>
        items={[]}
        valueToString={value => value}
        itemIdExtractor={v => v}
        value={undefined}
        onChange={() => {}}
        label={t("sale_segment")}
      />
      <SelectField<string>
        items={[]}
        valueToString={value => value}
        itemIdExtractor={v => v}
        value={undefined}
        onChange={() => {}}
        label={t("tariff")}
      />
      {/* FIXME listPaymentMethods není v API*/}
      <FormSelectField<ReservationFormData, OriginData>
        items={[]}
        valueToString={value => value.name}
        itemIdExtractor={v => v._id}
        field="header.preferredPaymentMethodId"
        label={t("expected_payment_method")}
      />
      <FormFieldAligner>
        <Tags>
          <Tag onDelete={() => alert("TODO")}>Tag 1</Tag>
          <Tag onDelete={() => alert("TODO")}>Tag 2</Tag>
          <Tag onClick={() => alert('"TODO')}>
            <Tooltipped tooltip={t("add_tag_tooltip")}>
              <Plus style={{ color: "white", cursor: "pointer" }} />
            </Tooltipped>
          </Tag>
        </Tags>
      </FormFieldAligner>
    </FlowLayout>
  );
}
