import { useTranslation } from "h11-client-component-lib";
import "./InventoryTable.scss";
import { InventoryRow } from "./InventoryRow";
import dayjs from "dayjs";

export const DUMMY_INVENTORY = [
  {
    id: 1,
    inventory: "extra_bed",
    room: "101",
    from: dayjs().subtract(1, "day"),
    to: dayjs().add(1, "day"),
    quantity: 1,
  },
  {
    id: 2,
    inventory: "extra_bed",
    room: "101",
    from: dayjs().subtract(1, "day"),
    to: dayjs().add(1, "day"),
    quantity: 1,
  },
  {
    id: 3,
    inventory: "extra_bed",
    room: "101",
    from: dayjs().subtract(1, "day"),
    to: dayjs().add(1, "day"),
    quantity: 1,
  },
];

export const InventoryTable = ({ groupIndex }: { groupIndex: number }) => {
  // Ordinary table is used, because Table component would be a overhead
  // TODO implement ordinary table also as a component? i. e. Table and rename the current Table to DataTable?

  const { t } = useTranslation();

  return (
    <div className="Table InventoryTable">
      <table>
        <thead>
          <tr>
            <th>{t("inventory")}</th>
            <th>{t("room")}</th>
            <th>{t("from")}</th>
            <th>{t("to")}</th>
            <th className="right">{t("quantity")}</th>
          </tr>
        </thead>
        <tbody>
          {DUMMY_INVENTORY.map(s => (
            <InventoryRow key={s.id} inventory={s} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
