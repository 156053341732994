import { ReservationFormData, RoomFormData } from "../../reservationData";
import { UseFieldArrayReturn } from "react-hook-form";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import {
  Button,
  Dropdown,
  DropdownControlled,
  NumberField,
  SelectFieldToggle,
  useTranslation,
} from "h11-client-component-lib";
import { useReservationContext } from "../../ReservationFormContext";

interface RoomsCountFieldProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

const RoomsCountField = forwardRef<HTMLElement, RoomsCountFieldProps>(
  ({ value, onChange }, ref) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 20,
        }}>
        <NumberField
          ref={ref}
          value={value}
          onChange={onChange}
          variant="buttoned"
          fieldWidth={40}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  },
);
RoomsCountField.displayName = "RoomsCountField";

export const RoomsCountSelectField = ({
  roomsFieldArray,
}: {
  roomsFieldArray: UseFieldArrayReturn<ReservationFormData, "groups.0.rooms">;
}) => {
  const { t } = useTranslation();
  const { createDefaultRoom } = useReservationContext();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLElement>(null);

  const roomsCount = useMemo(
    () => roomsFieldArray.fields.length ?? 0,
    [roomsFieldArray.fields],
  );

  const [roomsCountInternal, setRoomsCountInternal] = useState<
    number | undefined
  >(roomsCount);

  useEffect(() => {
    if (open) {
      setRoomsCountInternal(roomsCount);
    }
  }, [open]);

  useEffect(() => {
    setRoomsCountInternal(roomsCount);
  }, [roomsCount]);

  const commit = useCallback(() => {
    const newCount = roomsCountInternal ?? 0;
    // Modify rooms to match the new count
    const newRooms: RoomFormData[] = [...roomsFieldArray.fields];
    if (newCount !== roomsCount) {
      if (newCount > roomsCount) {
        for (let i = roomsCount; i < newCount; i++) {
          newRooms.push(createDefaultRoom());
        }
      } else {
        // FIXME sofistikovanější odebírání hostů a pokojů - kontrola, že ty co odebírám nejsou vyplněné, jinak varování, že to má udělat ručně
        newRooms.splice(newCount);
      }
      roomsFieldArray.replace(newRooms);
    }
  }, [
    roomsFieldArray.fields,
    roomsCount,
    roomsCountInternal,
    createDefaultRoom,
  ]);

  const changeOpen = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (open) {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      } else {
        commit();
      }
    },
    [commit, setOpen],
  );

  return (
    <DropdownControlled open={open} onChange={changeOpen}>
      <Dropdown.Control>
        <SelectFieldToggle
          fieldWidth={termsAndRoomTypesSectionHeadersWidths[2]}
          value={(roomsCountInternal ?? 0).toString()}
        />
      </Dropdown.Control>
      <Dropdown.Menu>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 4,
            padding: "0 16px",
            flex: "1",
          }}>
          <RoomsCountField
            ref={inputRef}
            value={roomsCountInternal}
            onChange={setRoomsCountInternal}
          />
          <Button
            variant="secondary"
            style={{ marginTop: 10 }}
            onClick={() => changeOpen(false)}>
            {t("ok")}
          </Button>
        </div>
      </Dropdown.Menu>
    </DropdownControlled>
  );
};
