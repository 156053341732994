import * as React from "react";
import { forwardRef, useMemo } from "react";
import {
  BaseSelectComponent,
  ExtraBed,
  FlowLayout,
  Icon,
  IconButton,
  RoomNumber,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import {
  ChatSquareTextFill,
  ClipboardCheckFill,
  ThreeDots,
} from "react-bootstrap-icons";
import { useFormContext, useWatch } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";
import { useReservationContext } from "../../ReservationFormContext";
import "./RoomHeaderRow.scss";

interface RoomHeaderRowProps {
  roomIndex: number;
  groupIndex: number;
}

const RoomSelectField = ({
  roomIndex,
  groupIndex,
}: {
  roomIndex: number;
  groupIndex: number;
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const {
    enums: { rooms },
  } = useReservationContext();

  const roomId = useWatch({
    control,
    name: `groups.${groupIndex}.rooms.${roomIndex}.roomId`,
  });

  const room = useMemo(
    () => rooms.find(r => r._id === roomId),
    [roomId, rooms],
  );

  // TODO umožnit výběr jen pokud je již přiřazen typ pokoje řádku a potom filtrovat seznamu pokojů

  // FIXME tohle je naprd, to není lepší způsob jak renderovat komponentu v BaseSelectComponent? Týká se více míst.
  const Toggle = useMemo(() => {
    const Comp = forwardRef<never, object>((bootstrapProps: object, ref) => (
      <Tooltipped tooltip={t("assigned_room_tooltip")}>
        <div className="RoomSelectField" ref={ref} {...bootstrapProps}>
          <RoomNumber>{room?.code}</RoomNumber>
        </div>
      </Tooltipped>
    ));
    Comp.displayName = "Toggle";
    return Comp;
  }, [roomId, rooms]);

  return (
    <BaseSelectComponent
      items={rooms}
      // non-null assertion, because the _id is optional only because of the graphql's union type
      itemIdExtractor={room => room._id!}
      renderValue={room => room.name}
      toggleComponent={Toggle}
      // TODO zvážit, jestli nepředávat jen ID?
      value={room}
      onChange={item => {
        setValue(`groups.${groupIndex}.rooms.${roomIndex}.roomId`, item?._id);
      }}
    />
  );
};

export const RoomHeaderRow = ({
  roomIndex,
  groupIndex,
}: RoomHeaderRowProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  const room = useWatch({
    control,
    name: `groups.${groupIndex}.rooms.${roomIndex}`,
  });
  const guests = useWatch({
    control,
    name: `groups.${groupIndex}.rooms.${roomIndex}.guests`,
  });

  const {
    enums: { rooms },
  } = useReservationContext();

  const assignedRoom = useMemo(() => {
    return rooms.find(rt => rt._id === room.roomId);
  }, [room.roomId]);

  return (
    <tr className="RoomHeaderRow">
      <td
        rowSpan={
          guests.length + 1 /* dummy hidden cells */ + 1 /*add guest button*/
        }>
        <div className="content">
          <FlowLayout variant="compact2" style={{ alignItems: "center" }}>
            <div className="item">
              <RoomSelectField groupIndex={groupIndex} roomIndex={roomIndex} />
            </div>
            <div className="item">
              <IconButton
                icon={ThreeDots}
                tooltip={t("other_room_options_tooltip")}
              />
            </div>
          </FlowLayout>
          <FlowLayout variant="compact2" style={{ alignItems: "center" }}>
            <div className="item">
              <Icon
                icon={ChatSquareTextFill}
                tooltip={t("room_note_tooltip")}
              />
            </div>
            <div className="item">
              <Icon
                icon={ClipboardCheckFill}
                tooltip={t("room_trace_tooltip")}
              />
            </div>
            <div className="item">
              <Icon icon={ExtraBed} tooltip={t("room_extra_bed_tooltip")} />
            </div>
          </FlowLayout>
        </div>
      </td>
      <td colSpan={7} />
    </tr>
  );
};
