import {
  Children,
  FC,
  isValidElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import "./PopupTextField.scss";
import { clsx } from "clsx";

export type PopupTextFieldProps = {
  popupHeight?: number;
  children: ReactNode;
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

interface PopupFieldComponent extends FC<PopupTextFieldProps> {
  Field: FC<FieldProps>;
  Popup: FC<PopupProps>;
}

export const PopupField: PopupFieldComponent = ({
  popupHeight = 300,
  children,
  onOpenChange,
  open,
}: PopupTextFieldProps) => {
  const [inFront, setInFront] = useState(false);

  useEffect(() => {
    if (open) {
      setInFront(true);
    } else {
      setTimeout(() => {
        setInFront(false);
      }, 300);
    }
  }, [open]);

  const childrenArray = Children.toArray(children);

  return (
    <div
      className={clsx("PopupTextField", { open })}
      style={{ zIndex: inFront ? 10 : 1 }}>
      <div style={{ position: "relative", zIndex: 15 }}>
        {childrenArray.filter(c => isValidElement(c) && c.type === Field)}
      </div>
      <div className="popup">
        <div
          className="content"
          style={{
            height: popupHeight,
          }}>
          {childrenArray.filter(c => isValidElement(c) && c.type === Popup)}
        </div>
        <div
          className="panel-background"
          style={{
            height: `calc(100% + ${popupHeight}px)`,
          }}
        />
        <div className="overlay" onClick={() => onOpenChange(false)}></div>
      </div>
    </div>
  );
};

interface FieldProps {
  children: ReactNode;
}

const Field = ({ children }: FieldProps) => {
  return <div className="Control">{children}</div>;
};
Field.displayName = "Field";

interface PopupProps {
  children: ReactNode;
}

const Popup = ({ children }: PopupProps) => {
  return <div className="Popup">{children}</div>;
};
Popup.displayName = "PopupField";

PopupField.Field = Field;
PopupField.Popup = Popup;
