import { ColumnDef } from "@tanstack/react-table";
import {
  BasicDropZone,
  IconButton,
  Table,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { useMemo } from "react";
import { Pencil, Trash } from "react-bootstrap-icons";
import dayjs from "dayjs";

export function AttachmentsPanel() {
  const { t } = useTranslation();

  const data = [
    {
      name: "Reservation",
      type: "PDF",
      uploaded: dayjs(new Date()).format("DD.MM.YYYY HH:mm"),
      size: "1.2 MB",
      user: "John Doe",
    },
    {
      name: "Reservation",
      type: "PDF",
      uploaded: dayjs(new Date()).format("DD.MM.YYYY HH:mm"),
      size: "1.2 MB",
      user: "John Doe",
    },
    {
      name: "Reservation",
      type: "PDF",
      uploaded: dayjs(new Date()).format("DD.MM.YYYY HH:mm"),
      size: "1.2 MB",
      user: "John Doe",
    },
  ];

  const columns: ColumnDef<(typeof data)[number]>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("title"),
        cell: row => (
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <a
              onClick={e => {
                e.preventDefault();
                alert("TODO");
              }}
              href="">
              {row.row.original.name}
            </a>
            <IconButton
              onClick={() => alert("TODO")}
              tooltip={t("edit_attachment_tooltip")}
              icon={Pencil}
            />
          </div>
        ),
      },
      {
        accessorKey: "type",
        header: t("type"),
      },
      {
        accessorKey: "uploaded",
        header: t("uploaded"),
      },
      {
        accessorKey: "size",
        header: t("size"),
      },
      {
        header: t("delete"),
        cell: row => (
          <IconButton
            onClick={() => alert("TODO")}
            tooltip={t("delete_attachment_tooltip")}
            icon={Trash}
          />
        ),
        meta: {
          center: true,
          hug: true,
        },
        enableHiding: false,
        enableSorting: false,
      },
    ],
    [],
  );
  return (
    <VerticalStack style={{ flex: "1" }}>
      <Table
        tableId="reservation-history"
        columns={columns}
        data={data}
        fullWidth
      />
      <BasicDropZone
        style={{ alignSelf: "flex-start" }}
        onAccept={() => alert("TODO")}
        accept={{ "image/jpeg": [".jpg"] }}
      />
    </VerticalStack>
  );
}
