import { useContext, useMemo } from "react";
import { TableContextFooter } from "./TableContextFooter";
import { ReservationsContext } from "./ReservationsPage";
import { Table, useHighlightAndFilter } from "h11-client-component-lib";
import { HighlightedReservationTableItem } from "./reservationsData";
import { ColumnDef } from "@tanstack/react-table";
import {
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from "react-relay";
import reservationsQuery, {
  ReservationsQuery,
} from "@relay-generated/ReservationsQuery.graphql";
import { ReservationsFragment$key } from "@relay-generated/ReservationsFragment.graphql";
import { reservationsFragment } from "./graphql/ReservationsFragment";

export function ReservationsTable({
  queryRef,
  search,
  columns,
  editReservation,
}: {
  queryRef: PreloadedQuery<ReservationsQuery>;
  search: string;
  columns: ColumnDef<HighlightedReservationTableItem>[];
  editReservation: (item: HighlightedReservationTableItem) => void;
}) {
  const reservationsContext = useContext(ReservationsContext);

  const preloadedQuery = usePreloadedQuery<ReservationsQuery>(
    reservationsQuery,
    queryRef,
  );
  const { data, loadNext, hasNext } = usePaginationFragment<
    ReservationsQuery,
    ReservationsFragment$key
  >(reservationsFragment, preloadedQuery);

  const reservations = useMemo(
    () => data.reservationsOverviewList.edges.map(e => e.node),
    [data],
  );

  const filteredData = useHighlightAndFilter(
    reservations,
    search,
    "name",
    "number",
    "orderNumber",
  ); // FIXME reference number v #HSF-111? Co to je? Transaction number asi ne, protože search tam nefunguje

  return (
    // TODO make "bloat" more general or separate the page more nicely
    reservationsContext && (
      <Table<HighlightedReservationTableItem>
        tableId="reservations"
        className="reservations-table bloat"
        columns={columns}
        data={filteredData}
        manualSorting
        sorting={reservationsContext.sorting}
        onSortingChange={reservationsContext.setSorting}
        columnFilters={reservationsContext.columnFilters}
        onColumnFiltersChange={reservationsContext.setColumnFilters}
        selection={reservationsContext.selection}
        onSelectionChange={reservationsContext.setSelection}
        onRowDoubleClick={editReservation}
        renderContextFooter={item => <TableContextFooter />}
        trProps={d =>
          d.status === "CANCELLED" ? { className: "cancelled" } : {}
        }
        hasNextPage={hasNext}
        fetchNextPage={() => loadNext(3 /*TODO více*/)}
      />
    )
  );
}
