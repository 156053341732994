import {
  GuestFormData,
  ReservationFormData,
  RoomFormData,
  RoomGroupFormData,
} from "./reservationData";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { EditPage } from "../to_lib/EditPage";
import { ReservationHeader } from "./ReservationHeader";
import { SummaryPanel } from "./summary/SummaryPanel";
import { MainSection } from "./main/MainSection";
import { CurrentGuestsSection } from "./current_guests/CurrentGuestsSection";
import { useCallback, useEffect, useRef } from "react";
import { ReservationFormContext } from "./ReservationFormContext";
import { useTranslation } from "h11-client-component-lib";
import { ReservationDetailEnumsFragment$data } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { TermsAndRoomTypesSection } from "./terms_and_room_types/TermsAndRoomTypesSection";
import { DebugPanel } from "./debug/DebugPanel";

export function ReservationForm({
  data,
  enums,
  autoAddInitialData,
}: {
  data: ReservationFormData;
  enums: ReservationDetailEnumsFragment$data;
  autoAddInitialData?: boolean;
}) {
  const { t } = useTranslation();

  // Next internal sequences for use in temporary UIDs for use in react hook form and dnd-kit
  const nextNewGroupSeq = useRef(1);
  const nextNewRoomSeq = useRef(1);
  const nextNewGuestSeq = useRef(1);

  const nextGroupTempUid = useCallback(
    () => `NEW-${nextNewGroupSeq.current++}`,
    [],
  );

  const nextRoomTempUid = useCallback(
    () => `NEW-${nextNewRoomSeq.current++}`,
    [],
  );

  const nextGuestTempUid = useCallback(
    () => `NEW-${nextNewGuestSeq.current++}`,
    [],
  );

  /* const { formik } = useApiForm<
    ReservationFormData,
    typeof reservationSchema,
    { dummyRequest: { dummyResult: string } }
  >({
    initialValues: {},
    schema: reservationSchema,
    onSubmit: async responses => {
      return { responses: { dummyRequest: { dummyResult: "TODO" } } };
    },
    onSuccess: responses => {},
  });*/

  const form = useForm<ReservationFormData>({
    defaultValues: data,
  });
  const { control } = form;

  const groups = useFieldArray({
    control,
    name: "groups",
  });

  console.debug("Reservation form render");

  const createDefaultGuest = useCallback(
    (childCategoryId: number | null): GuestFormData => {
      return {
        uid: nextGuestTempUid(),
        childCategoryId,
        firstName: "",
        lastName: "",
        birthDate: null,
        note: null,
        email: "",
        citizenshipCountry: null,
      };
    },
    [nextGuestTempUid],
  );

  const createDefaultRoom = useCallback((): RoomFormData => {
    return {
      uid: nextRoomTempUid(),
      roomId: null,
      guests: [createDefaultGuest(null)],
    };
  }, [nextRoomTempUid, createDefaultGuest]);

  const addRoomGroup = useCallback(() => {
    groups.prepend({
      uid: nextGroupTempUid(),
      checkinDate: undefined,
      roomTypeId: undefined,
      checkoutDate: undefined,
      rooms: [createDefaultRoom()],
    } as RoomGroupFormData);
  }, [groups, createDefaultRoom]);

  const initialDataAdded = useRef(false);

  const isNew = false; //!data.uid;

  useEffect(() => {
    if (autoAddInitialData && !initialDataAdded.current) {
      addRoomGroup();
      initialDataAdded.current = true;
    }
  }, []);

  return (
    <>
      <ReservationFormContext.Provider
        value={{
          enums,
          nextGroupTempUid,
          nextRoomTempUid,
          nextGuestTempUid,
          createDefaultGuest,
          createDefaultRoom,
        }}>
        <FormProvider {...form}>
          <form
            onSubmit={() => {
              console.log("SUBMIT");
            }}>
            <EditPage>
              <EditPage.Header>
                <ReservationHeader />
              </EditPage.Header>
              <EditPage.Content>
                <MainSection />
                <TermsAndRoomTypesSection
                  groups={groups}
                  onAddRoomGroup={addRoomGroup}
                />
                {!isNew && <CurrentGuestsSection />}
              </EditPage.Content>
              <EditPage.Summary>
                <SummaryPanel />
              </EditPage.Summary>
            </EditPage>
            <DebugPanel />
          </form>
        </FormProvider>
      </ReservationFormContext.Provider>
    </>
  );
}
