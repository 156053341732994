import {
  FlowLayout,
  Icon,
  IconButton,
  IconText,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import {
  ExclamationTriangle,
  Moon,
  Pencil,
  People,
  Person,
} from "react-bootstrap-icons";
import { useFormContext, useWatch } from "react-hook-form";
import { ReservationFormData } from "../reservationData";
import "./SummaryPanel.scss";
import { useGuestsCount, useNightsCount } from "../reservationHooks";

const NightsCount = () => {
  const nightsCount = useNightsCount();
  const { t } = useTranslation();
  return nightsCount !== undefined ? (
    <IconText
      icon={Moon}
      text={`${nightsCount}x`}
      tooltip={t("nights_count_tooltip")}
    />
  ) : undefined;
};

const GuestsCount = () => {
  const { totalCount } = useGuestsCount();
  const { t } = useTranslation();
  return totalCount ? (
    <IconText
      icon={Person}
      text={`${totalCount}x`}
      tooltip={t("guests_count_tooltip")}
    />
  ) : undefined;
};

export const SummaryPanel = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();

  const referenceNumber = useWatch({
    control,
    name: "header.referenceNumber",
  });

  const isGroup = useWatch({ control, name: "header.isGroup" });

  console.debug("SummaryPanel render");

  return (
    <div className="SummaryPanel">
      <VerticalStack variant="compact">
        <h3 className="Font--part-title">{t("basic_information")}</h3>
        <FlowLayout>
          <GuestsCount />
          <NightsCount />
        </FlowLayout>
        <FlowLayout>
          {referenceNumber && <div>№: {referenceNumber}</div>}
          {/*TODO PIN v API*/}
          <div>PIN: 1234</div>
          {isGroup && <Icon icon={People} tooltip={t("is_group_tooltip")} />}
        </FlowLayout>
        <FlowLayout>
          <div>
            {t("distributor")}: D-Edge{/*TODO*/}
          </div>
          <div>123456789{/*TODO*/}</div>
          <IconButton
            onClick={() => alert("TODO")}
            tooltip={t("edit_distributor_order_number_tooltip")}
            icon={Pencil}
          />
        </FlowLayout>
        <table className="summary-table" style={{ marginTop: 96 }}>
          <tbody>
            <tr>
              <td>{t("warning")}:</td>
              <td>
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <Icon
                    className="warning"
                    icon={ExclamationTriangle}
                    tooltip={t("warning_tooltip")}
                  />
                  <span>
                    <a href="#">Lorem Ipsum is simply...</a>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>{t("note")}:</td>
              <td>
                <a href="#">Lorem Ipsum is simply...</a>
              </td>
            </tr>
            <tr>
              <td>{t("trace")}:</td>
              <td>
                <a href="#">Lorem Ipsum is simply...</a>
              </td>
            </tr>
            <tr>
              <td>{t("author")}:</td>
              <td>Lorem Ipsum, 05.02.23 12:31</td>
            </tr>
            <tr>
              <td>{t("edited")}:</td>
              <td>Lorem Ipsum, 05.02.23 12:31</td>
            </tr>
            <tr>
              <td>{t("proforma")}:</td>
              <td>
                <a href="#">P123456789</a>, 20%, 05.02.23 12:31
              </td>
            </tr>
            <tr>
              <td>{t("confirmation")}:</td>
              <td>
                <a href="#">P123456789</a>, CZ, 05.02.23 12:31
              </td>
            </tr>
            <tr>
              <td>{t("prepayments")}:</td>
              <td>
                <a href="#">100 EUR</a>, B
              </td>
            </tr>
          </tbody>
        </table>
        <h3
          className="Font--part-title"
          style={{ marginTop: 36, marginBottom: 28 }}>
          {t("reservation_summary")}
        </h3>
        <table className="summary-table">
          <tbody>
            <tr>
              <td>{t("rooms")}:</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("adults_count")}:</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("children_count")}:</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("accommodation_price")}:</td>
              <td>21 450,- CZK</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                <hr />
              </td>
            </tr>
            <tr className="total">
              <td>{t("total_price")}:</td>
              <td>28 563,- CZK</td>
            </tr>
          </tfoot>
        </table>
      </VerticalStack>
    </div>
  );
};
