import { useFieldArray, useFormContext } from "react-hook-form";
import * as React from "react";
import { RoomHeaderRow } from "./RoomHeaderRow";
import { ReservationFormData } from "../reservationData";
import { GuestRow } from "./GuestRow";

export function RoomRows({ roomIndex }: { roomIndex: number }) {
  const { control } = useFormContext<ReservationFormData>();

  const guests = useFieldArray({
    control,
    name: `checkedInRooms.${roomIndex}.guests`,
  });

  return (
    <>
      <RoomHeaderRow key={"room-header-" + roomIndex} roomIndex={roomIndex} />
      {guests.fields.map((g, index) => {
        return <GuestRow key={g.id} guestIndex={index} roomIndex={roomIndex} />;
      })}
    </>
  );
}
