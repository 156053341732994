import { useFormikContext } from "formik";
import {
  Button,
  FlowLayout,
  FormGrid,
  FormikSelectField,
  FormikTextField,
  Icon,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { RoleFormData, SystemUserRole } from "./roleData";
import { RightsSettingsTab } from "@shared/ui/resources/RightsSettingsTab";
import { ArrowCounterclockwise, InfoCircle } from "react-bootstrap-icons";
import { ResourceRightsDetail } from "@shared/data/ResourceRightsData";
import { useFixedResourceRights } from "@shared/ui/resources/resourcesUtil";

export function RoleForm({
  systemRoles,
  resourcesDefinitions,
}: {
  systemRoles: readonly SystemUserRole[];
  resourcesDefinitions: ResourceRightsDetail[];
}) {
  const { t } = useTranslation();

  const formik = useFormikContext<RoleFormData>();

  const fixedCustomResourceRights = useFixedResourceRights(
    resourcesDefinitions,
    undefined,
    formik.values.systemRole ?? undefined,
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 50,
        minHeight: 300,
      }}>
      <FormGrid
        gridTemplateColumns="repeat(4, minmax(100px, 1fr))"
        style={{ flex: "1", minHeight: 0 }}>
        <FormikTextField label={t("name")} field="name" />

        <FormikSelectField
          label={t("system_role")}
          field="systemRole"
          items={systemRoles}
          itemIdExtractor={r => r.code}
          valueToString={r => r.name}
        />

        <FormikTextField
          multiline
          style={{ gridColumn: "1 / 4" }}
          label={t("description")}
          field="description"
        />

        <div style={{ gridColumn: "1 / 5", minHeight: 0 }}>
          <RightsSettingsTab
            resourcesDefinitions={resourcesDefinitions}
            fixedCustomResourceRights={fixedCustomResourceRights}
            fixedApps={[]}
          />
        </div>
        <FlowLayout
          style={{ gridColumn: "1 / 5", gap: 8, alignItems: "center" }}>
          <Button
            icon={<ArrowCounterclockwise />}
            variant="secondary"
            onClick={() => {
              formik.setFieldValue("customResourceRights", [], true);
            }}>
            {t("reset_all_by_system_role")}
          </Button>
          <Icon
            icon={InfoCircle}
            tooltip={t("reset_all_by_system_role_hint")}
          />
        </FlowLayout>
      </FormGrid>
    </div>
  );
}
