import {
  Panel,
  SimpleTab,
  SimpleTabs,
  useTranslation,
} from "h11-client-component-lib";
import { OverviewPanel } from "./overview/OverviewPanel";
import { NotePanel } from "./note/NotePanel";
import "./MainSection.scss";
import { TracesPanel } from "./traces/TracesPanel";
import { HistoryPanel } from "./history/HistoryPanel";
import { AttachmentsPanel } from "./attachments/AttachmentsPanel";
import { ConditionsPanel } from "./conditions/ConditionsPanel";
import { OrderedByPanel } from "./ordered_by/OrderedByPanel";

export const MainSection = () => {
  const { t } = useTranslation();

  console.debug("MainSection render");

  return (
    <Panel className="MainSection">
      <SimpleTabs style={{ flex: "1" }}>
        <SimpleTab title={t("overview")}>
          <OverviewPanel />
        </SimpleTab>
        <SimpleTab title={t("note")}>
          <NotePanel />
        </SimpleTab>
        <SimpleTab title={t("traces")}>
          <TracesPanel />
        </SimpleTab>
        <SimpleTab title={t("history")}>
          <HistoryPanel />
        </SimpleTab>
        <SimpleTab title={t("attachments")}>
          <AttachmentsPanel />
        </SimpleTab>
        <SimpleTab title={t("conditions")}>
          <ConditionsPanel />
        </SimpleTab>
        <SimpleTab title={t("ordered_by")}>
          <OrderedByPanel />
        </SimpleTab>
      </SimpleTabs>
    </Panel>
  );
};
