import { DUMMY_INVENTORY } from "./InventoryTable";
import { RoomNumber } from "h11-client-component-lib";

export const InventoryRow = ({
  inventory,
}: {
  inventory: (typeof DUMMY_INVENTORY)[number];
}) => {
  return (
    <tr>
      <td>{inventory.inventory}</td>
      <td>
        <div style={{ display: "flex" }}>
          <RoomNumber>{inventory.room}</RoomNumber>
        </div>
      </td>
      <td>{inventory.from.format("DD.MM.YYYY")}</td>
      <td>{inventory.to.format("DD.MM.YYYY")}</td>
      <td className="right">{inventory.quantity}</td>
    </tr>
  );
};
