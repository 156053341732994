import { useEffect } from "react";
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import "./index.scss";
import "./to_lib.scss";
import { ReservationUpdateQuery } from "@relay-generated/ReservationUpdateQuery.graphql";
import { reservationUpdateQuery } from "./graphql/ReservationUpdateQuery";
import { useParams } from "react-router";
import { ReservationDetailEnumsFragment$key } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { reservationDetailEnumsFragment } from "./graphql/ReservationDetailEnumsFragment";
import { ReservationForm } from "./ReservationForm";
import { reservationDetailFragment } from "./graphql/ReservationDetailFragment";
import { ReservationDetailFragment$key } from "@relay-generated/ReservationDetailFragment.graphql";

export function ReservationUpdatePage() {
  const { uid } = useParams<{ uid: string }>();

  const [queryRef, loadQuery] = useQueryLoader<ReservationUpdateQuery>(
    reservationUpdateQuery,
  );

  useEffect(() => {
    if (uid) {
      loadQuery({ uid });
    }
  }, [uid]);

  if (uid) {
    return queryRef && <LoadedReservationUpdatePage queryRef={queryRef} />;
  } else {
    return null;
  }
}

function LoadedReservationUpdatePage({
  queryRef,
}: {
  queryRef: PreloadedQuery<ReservationUpdateQuery>;
}) {
  const reservationData = usePreloadedQuery(reservationUpdateQuery, queryRef);

  const enums = useFragment<ReservationDetailEnumsFragment$key>(
    reservationDetailEnumsFragment,
    reservationData,
  );

  const data = useFragment<ReservationDetailFragment$key>(
    reservationDetailFragment,
    reservationData,
  ).reservationGet;

  if (!data) {
    // TODO
    return null;
  }

  return (
    <ReservationForm
      data={{
        // FIXME v API co nejvíc sjednotit objekt pro update/insert a query, abych to nemusel takhle blbě mapovat
        uid: data.uid,
        header: {
          // TODO jak může být header undefined?
          sourceId: data.header!.sourceId,
          reservationName: data.header!.reservationName,
          referenceNumber: data.header!.referenceNumber,
        },
        groups: data.groupRooms.map(g => ({
          uid: g.uid,
          checkinDate: g.checkinDate,
          checkoutDate: g.checkoutDate,
          roomTypeId: g.roomTypeId,
          rooms: g.rooms.map(r => ({
            uid: r.uid,
            roomId: r.roomId,
            guests: r.guests.map(g => ({
              uid: g.uid,
              firstName: g.firstName,
              lastName: g.lastName,
              birthDate: g.birthDate,
              note: g.note,
              citizenshipCountry: g.citizenshipCountry,
              childCategoryId: g.childCategoryId,
            })),
          })),
        })),
      }}
      enums={enums}
    />
  );
}
