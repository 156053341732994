import dayjs from "dayjs";
import { GuestFormData } from "./reservationData";

// Returns guest child category or null if it cannot be determined or the guest is an adult
export function determineGuestChildCategory(guest: GuestFormData) {
  // TODO
  if (guest.birthDate) {
    const age = dayjs().diff(dayjs(guest.birthDate), "year");
    if (age < 4) {
      return 1;
    }
    if (age < 12) {
      return 2;
    }
    if (age < 18) {
      return 3;
    }
    return null;
  }

  return guest.childCategoryId ?? null;
}
