import {
  FlowGroup,
  FlowLayout,
  Icon,
  IconButton,
  RoomNumber,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import {
  Bell,
  ExclamationTriangle,
  Eye,
  Pencil,
  Trash,
} from "react-bootstrap-icons";

export const Trace = () => {
  const { t } = useTranslation();
  return (
    <VerticalStack className="Trace" variant="compact">
      <RoomNumber>{t("for_all_reservations_rooms")}</RoomNumber>
      <div>Na všechny pokoje každé ráno program konference</div>
      <FlowLayout style={{ alignItems: "flex-end" }}>
        <FlowLayout style={{ flex: "1" }} className="flags">
          <FlowGroup style={{ alignItems: "center" }}>
            <Icon
              icon={ExclamationTriangle}
              className="warning"
              tooltip={t("trace_warning_tooltip")}
            />
            <Icon icon={Eye} tooltip={t("trace_show_when_tooltip")} />
            {t("on_checkin")}
          </FlowGroup>
          <FlowGroup style={{ alignItems: "center" }}>
            <Icon icon={Bell} tooltip={t("trace_alert_tooltip")} />
            <div>28.09.23 19:45</div>
          </FlowGroup>
          <FlowGroup>
            <div>{t("intended_for")}: FO, HSK</div>
          </FlowGroup>
        </FlowLayout>
        <FlowGroup>
          <IconButton
            variant="filled"
            size={32}
            onClick={() => alert("TODO")}
            tooltip={t("edit_trace_tooltip")}
            icon={Pencil}
          />
          <IconButton
            variant="filled"
            size={32}
            onClick={() => alert("TODO")}
            tooltip={t("delete_trace_tooltip")}
            icon={Trash}
          />
        </FlowGroup>
      </FlowLayout>
    </VerticalStack>
  );
};
