import { useTranslation } from "h11-client-component-lib";
import { useFormContext } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";
import { ServicesTable } from "./ServicesTable";

export const AdditionalServicesPanel = ({
  groupIndex,
}: {
  groupIndex: number;
}) => {
  return <ServicesTable groupIndex={groupIndex} />;
};
