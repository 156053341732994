import { Children, FC, isValidElement, ReactElement, ReactNode } from "react";
import "./EditPage.scss"; //FIXME vyhodit

type EditPageChildType =
  | ReactElement<HeaderProps, typeof Header>
  | ReactElement<SummaryProps, typeof Summary>;

interface EditPageProps {
  children: EditPageChildType | EditPageChildType[];
}

export function EditPage({ children }: EditPageProps) {
  return (
    <div className="EditPage">
      {Children.toArray(children).filter(c => {
        return isValidElement(c) && c.type === Header;
      })}
      <div className="scroller">
        <div className="main">
          {Children.toArray(children).filter(c => {
            return (
              isValidElement(c) && (c.type === Content || c.type === Summary)
            );
          })}
        </div>
      </div>
    </div>
  );
}

EditPage.displayName = "EditPage";

export interface HeaderProps {
  children: ReactNode;
}

export function Header({ children }: HeaderProps) {
  return <div className="ReservationHeader">{children}</div>;
}

Header.displayName = "Header";

export interface SummaryProps {
  children: ReactNode;
}

export const Summary: FC<SummaryProps> = ({ children }) => (
  <div className="Summary">{children}</div>
);

Summary.displayName = "Summary";

export interface ContentProps {
  children: ReactNode;
}

export const Content: FC<ContentProps> = ({ children }) => (
  <div className="Content">{children}</div>
);

Content.displayName = "Content";

EditPage.Header = Header;
EditPage.Summary = Summary;
EditPage.Content = Content;
