import {
  FlowLayout,
  TextField,
  useTranslation,
} from "h11-client-component-lib";

export function OrderedByPanel() {
  const { t } = useTranslation();
  return (
    <FlowLayout>
      <TextField
        value=""
        label={t("ordered_by")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <TextField
        value=""
        label={t("order_number")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <TextField
        value=""
        label={t("email")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <TextField
        value=""
        label={t("phone_number")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <TextField
        value=""
        label={t("address")}
        multiline
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1, minHeight: 100 }}
      />
      <TextField
        value=""
        label={t("note")}
        multiline
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
    </FlowLayout>
  );
}
