import {
  Button,
  FlowLayout,
  Heading,
  SelectField,
  TextField,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { ClipboardCheck, ClipboardPlus, Sliders } from "react-bootstrap-icons";
import { Trace } from "./Trace";
import "./index.scss";

export function TracesPanel() {
  const { t } = useTranslation();
  // TODO změnit na form komponenty
  return (
    <FlowLayout variant="compact" style={{ flex: "1", alignItems: "stretch" }}>
      <VerticalStack style={{ flex: "1 1 200px", minWidth: 200 }}>
        <Heading icon={<ClipboardPlus />}>{t("new_trace")}</Heading>
        <SelectField
          label={t("trace_template")}
          value=""
          items={["trace1", "trace2", "trace3"]}
          valueToString={item => item}
          itemIdExtractor={item => item}
          onChange={() => {}}
        />
        <TextField label={t("trace_text")} value="" multiline />
        <SelectField
          // TODO
          label={t("intended_for")}
          items={["front_office", "f&b", "housekeeping", "maintenance"]}
          valueToString={i => i}
          itemIdExtractor={i => i}
          value={[]}
          onChange={() => {}}
          multiSelect
          selectAllLabel={t("all_groups")}
        />
        <FlowLayout
          variant="compact"
          style={{ justifyContent: "space-between" }}>
          <Button variant="secondary" icon={<Sliders />}>
            {t("more_options")}
          </Button>
          <Button variant="secondary">{t("save_trace")}</Button>
        </FlowLayout>
      </VerticalStack>
      <VerticalStack
        style={{ flex: "1 1 200px", minWidth: 200 }}
        variant="compact">
        <Heading icon={<ClipboardCheck />}>{t("reservation_traces")}</Heading>
        <VerticalStack variant="squished">
          <Trace />
          <Trace />
          <Trace />
        </VerticalStack>
      </VerticalStack>
    </FlowLayout>
  );
}
